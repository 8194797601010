/* 1. Components */
@import 'components/input';

/* 2. Third party */
@import 'perfect-scrollbar/css/perfect-scrollbar.css';
//@import "@ag-grid-community/styles/ag-grid.min.css";
//@import "@ag-grid-community/styles/ag-theme-material.min.css";

/* 3. Overrides */
@import 'overrides/angular-material';
@import 'overrides/ag-grid';
@import 'overrides/highlightjs';
@import 'overrides/perfect-scrollbar';

@import 'overrides/primeng';
@import 'overrides/fuse';

/* 4. Tailwind */

// @layer tailwind-utilities {
    @tailwind utilities;
//}

/* Reset CSS */
// @layer reset {
//     button,
//     input {
//         /* CSS to Reset */
//     }
// }
