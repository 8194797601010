.fuse-vertical-navigation-item-badge {
  margin-left: 0px !important;
  margin-top: -10px !important;
}

.fuse-horizontal-navigation-item-badge {
  margin-left: 0px !important;
  margin-top: -20px !important;
}

.fuse-horizontal-navigation-item-badge-content {
  background-color: var(--fuse-primary) !important;
  color: white !important;
}

.fuse-vertical-navigation-item-badge-content {
  color: var(--fuse-primary) !important;
  background-color: white !important;
}

.fuse-vertical-navigation, .fuse-vertical-navigation-wrapper, .fuse-vertical-navigation-content, .fuse-vertical-navigation-content-header {
  text-align: -webkit-center;
}


