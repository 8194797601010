@use "@ag-grid-community/styles" as ag;

@include ag.grid-styles((
  // ^^^ Include ag.grid-styles which it is no longer nested  in .ag-theme-{name} {}
  //theme: material-auto-dark,
  themes: (
    material:(
      material-primary-color: var(--fuse-primary),
      material-secondary-color: var(--fuse-secondary),
    ), 
    material-dark:(
      material-primary-color: var(--fuse-primary),
      material-secondary-color: var(--fuse-secondary),
    )
  ),
  // balham-primary-color: var(--fuse-primary),
  // balham-secondary-color: var(--fuse-secondary),

  // ^^^ Add the theme name to the mixin
  //material-primary-color: var(--fuse-primary),
  //material-secondary-color: var(--fuse-secondary),
// ^^^ Pass the same parameters. The new API validates parameters so you will be
  //     notified if any of the names or values you pass are not valid for the new API
));

@include ag.text-input {
  color: #999;
}

.ag-theme-material {
    font-size: inherit;  
    font-family: inherit;
    .ag-row {
      border-left: var(--ag-borders-row) var(--ag-row-border-color);
      border-right: var(--ag-borders-row) var(--ag-row-border-color);
      border-left-width: 1px;
      border-right-width: 1px;
      cursor: pointer;
    }

    .ag-invisible {
      display: none !important;
    }

    .ag-header {
      @apply border;
    }

    .ag-header-row {
      @apply shadow;
      @apply bg-gray-50;
      @apply text-secondary;
      @apply font-semibold;
      @apply text-md;
      height: 28px;
    }
    .ag-header-row-column-filter {
      background-color: transparent;
    }
    .ag-watermark {
      display: none;
    }
  
    // .ag-row-focus {
    //   border-color: var(--fuse-primary);
    //   border-width: 1px;
    //   border-style: dashed;
    // }

    .ag-cell-focus {
      border-width: 0px !important;
    }

    .ag-cell-range-selected {
      border-width: 0px !important;
    }

    button.ag-side-button-button {
      min-height: auto !important;
    }
    --ag-line-height: 28px;
    --ag-row-height: 28px;
    --ag-font-family: inherit;
    --ag-font-size: inherit;
    --ag-row-border-color: var(--fuse-primary-200);
    --ag-grid-size: 6px;
    --ag-input-focus-border-color: rgb(203 213 225 / var(--tw-border-opacity)) !important;

    //--ag-selected-row-background-color: var(--fuse-primary-100);
    // --ag-range-selection-border-color: rgb(193, 0, 97);
    // --ag-range-selection-border-style: dashed;
    // --ag-range-selection-background-color: rgb(255, 0, 128, 0.1);
    // --ag-range-selection-background-color-2: rgb(255, 0, 128, 0.19);
    // --ag-range-selection-background-color-3: rgb(255, 0, 128, 0.27);
    // --ag-range-selection-background-color-4: rgb(255, 0, 128, 0.34);
    // --ag-range-selection-highlight-color: rgb(60, 188, 0, 0.3);
    // --ag-alpine-active-color
    // CSS color (e.g. `red` or `#fff`)
    // (Alpine theme only) accent colour used for checked checkboxes, range selections, row hover, row selections, selected tab underlines, and input focus outlines in the Alpine theme
    // --ag-balham-active-color
    // CSS color (e.g. `red` or `#fff`)
    // (Balham theme only) accent colour used for checked checkboxes, range selections, row selections, and input focus outlines in the Balham theme
    --ag-material-primary-color: var(--fuse-primary);
    // CSS color (e.g. `red` or `#fff`)
    // (Material theme only) the primary colour as defined in the Material Design colour system. Currently this is used on buttons, range selections and selected tab underlines in the Material theme
    // --ag-material-accent-color
    // CSS color (e.g. `red` or `#fff`)
    // (Material theme only) the accent colour as defined in the Material Design colour system. Currently this is used on checked checkboxes in the Material theme
    // --ag-foreground-color
    // CSS color (e.g. `red` or `#fff`)
    // Colour of text and icons in primary UI elements like menus
    // --ag-background-color
    // CSS color (e.g. `red` or `#fff`)
    // Background colour of the grid
    // --ag-secondary-foreground-color
    // CSS color (e.g. `red` or `#fff`)
    // Colour of text and icons in UI elements that need to be slightly less emphasised to avoid distracting attention from data
    // --ag-data-color
    // CSS color (e.g. `red` or `#fff`)
    // Colour of text in grid cells
    // --ag-header-foreground-color
    // CSS color (e.g. `red` or `#fff`)
    // Colour of text and icons in the header
    // --ag-header-background-color
    // CSS color (e.g. `red` or `#fff`)
    // Background colour for all headers, including the grid header, panels etc
    // --ag-disabled-foreground-color
    // CSS color (e.g. `red` or `#fff`)
    // Color of elements that can't be interacted with because they are in a disabled state
    // --ag-subheader-background-color
    // CSS color (e.g. `red` or `#fff`)
    // Background colour for second level headings within UI components
    // --ag-subheader-toolbar-background-color
    // CSS color (e.g. `red` or `#fff`)
    // Background colour for toolbars directly under subheadings (as used in the chart settings menu)
    // --ag-control-panel-background-color
    // CSS color (e.g. `red` or `#fff`)
    // Background for areas of the interface that contain UI controls, like tool panels and the chart settings menu
    // --ag-side-button-selected-background-color
    // CSS color (e.g. `red` or `#fff`)
    // Background for the active tab on the side of the control panel
    // --ag-selected-row-background-color
    // CSS color (e.g. `red` or `#fff`)
    // Background color of selected rows in the grid and in dropdown menus
    --ag-odd-row-background-color: var(--ag-background-color);
    // CSS color (e.g. `red` or `#fff`)
    // Background colour applied to every other row
    // --ag-modal-overlay-background-color
    // CSS color (e.g. `red` or `#fff`)
    // Background color of the overlay shown over the grid e.g. a data loading indicator
    --ag-row-hover-color: rgb(241 245 249 / var(--tw-bg-opacity));
    // CSS color (e.g. `red` or `#fff`)
    // Background color when hovering over rows in the grid and in dropdown menus. Set to transparent to disable the hover effect. Note: if you want a rollover on one but not the other, use CSS selectors instead of this property
    // --ag-column-hover-color
    // CSS color (e.g. `red` or `#fff`)
    // Background color when hovering over columns in the grid
    // --ag-range-selection-border-color
    // CSS color (e.g. `red` or `#fff`)
    // Color to draw around selected cell ranges
    // --ag-range-selection-border-style
    // a CSS border style (e.g. `dotted`, `solid` or `none`)
    // Border style for range selections, e.g. solid or dashed. Do not set to none, if you need to hide the border set the color to transparent
    // --ag-range-selection-background-color
    // CSS color (e.g. `red` or `#fff`)
    // Background colour of selected cell ranges.
    // --ag-range-selection-background-color-2
    // CSS color (e.g. `red` or `#fff`)
    // Background-color when 2 selected ranges overlap. Hint: for a realistic appearance of multiple semi-transparent colours overlaying, set the opacity to 1-((1-X)^2) where X is the opacity of --ag-range-selection-background-color
    // --ag-range-selection-background-color-3
    // CSS color (e.g. `red` or `#fff`)
    // Background-color when 3 selected ranges overlap. Hint: for a realistic appearance of multiple semi-transparent colours overlaying, set the opacity to 1-((1-X)^3) where X is the opacity of --ag-range-selection-background-color
    // --ag-range-selection-background-color-4
    // CSS color (e.g. `red` or `#fff`)
    // Background-color when 4 or more selected ranges overlap. Hint: for a realistic appearance of multiple semi-transparent colours overlaying, set the opacity to 1-((1-X)^4) where X is the opacity of --ag-range-selection-background-color
    // --ag-range-selection-highlight-color
    // CSS color (e.g. `red` or `#fff`)
    // Background colour to briefly apply to a cell range when it is copied from or pasted into
    // --ag-selected-tab-underline-color
    // CSS color (e.g. `red` or `#fff`)
    // Colour of the border drawn under selected tabs
    // --ag-selected-tab-underline-width
    // CSS size (e.g. `0`, `4px` or `50%`)
    // Thickness of the border drawn under selected tabs
    // --ag-selected-tab-underline-transition-speed
    // CSS time duration (e.g. `3s` or `250ms`)
    // Duration of animation used to show and hide the border drawn under selected tabs
    // --ag-range-selection-chart-category-background-color
    // CSS color (e.g. `red` or `#fff`)
    // Background colour for cells that provide categories to the current range chart
    // --ag-range-selection-chart-background-color
    // CSS color (e.g. `red` or `#fff`)
    // Background colour for cells that provide data to the current range chart
    // --ag-header-cell-hover-background-color
    // CSS color (e.g. `red` or `#fff`)
    // Rollover colour for header cells. If you set this variable and have enabled column reordering by dragging, you may want to set --ag-header-cell-moving-background-color to ensure that the rollover colour remains in place during dragging.
    // --ag-header-cell-moving-background-color
    // CSS color (e.g. `red` or `#fff`)
    // Colour applied to header cells when the column is being dragged to a new position
    // --ag-value-change-value-highlight-background-color
    // CSS color (e.g. `red` or `#fff`)
    // Colour to apply when a cell value changes and enableCellChangeFlash is enabled
    // --ag-value-change-delta-up-color
    // CSS color (e.g. `red` or `#fff`)
    // Colour to temporarily apply to cell data when its value increases in an agAnimateShowChangeCellRenderer cell
    // --ag-value-change-delta-down-color
    // CSS color (e.g. `red` or `#fff`)
    // Colour to temporarily apply to cell data when its value decreases in an agAnimateShowChangeCellRenderer cell
    // --ag-chip-background-color
    // CSS color (e.g. `red` or `#fff`)
    // Colour for the 'chip' that represents a column that has been dragged onto a drop zone
    //--ag-borders: solid 1px;
    // a CSS border style and size (e.g. `solid 1px` or `none`)
    // Enable or disable borders around most UI elements in the grid. Set this to a border style and thickness, e.g. solid 1px to enable borders, or none to disable borders. Use the other --ag-borders-* variables for more fine grained control over which UI elements get borders.
    // --ag-border-color
    // CSS color (e.g. `red` or `#fff`)
    // Colour for border around major UI components like the grid itself, headers; footers and tool panels.
    // --ag-borders-critical
    // a CSS border style and size (e.g. `solid 1px` or `none`)
    // Enable or disable borders that are critical to UX, e.g. those between headers and rows. Themes that disable borders generally may want to enable these borders. Set this to a border style and thickness, e.g. solid 1px to enable borders, or none to disable borders.
    // --ag-borders-secondary
    // a CSS border style and size (e.g. `solid 1px` or `none`)
    // Draw decorative borders separating UI elements within components. Set this to a border style and thickness, e.g. solid 1px to enable borders, or none to disable borders.
    // --ag-secondary-border-color
    // CSS color (e.g. `red` or `#fff`)
    // Colour for borders used to separate elements within a major UI component
    // --ag-borders-row
    // a CSS border style and size (e.g. `solid 1px` or `none`)
    // Draw borders between rows. Set this to a border style and thickness, e.g. solid 1px to enable borders, or none to disable borders.
    //--ag-cell-horizontal-border: solid 1px var(--ag-row-border-color);
    // a CSS border style and colour (e.g. `solid red`)
    // Default border for cells. This can be used to specify the border-style and border-color properties e.g. dashed red but the border-width is fixed at 1px. Set to solid transparent to show no border.
    // --ag-borders-input
    // a CSS border style and size (e.g. `solid 1px` or `none`)
    // Draw borders around inputs. Set this to a border style and thickness, e.g. solid 1px to enable borders, or none to disable borders.
    // --ag-input-border-color
    // CSS color (e.g. `red` or `#fff`)
    // Colour for borders around inputs, if enabled with --ag-borders-input
    // --ag-borders-input-invalid
    // a CSS border style and size (e.g. `solid 1px` or `none`)
    // Draw borders around inputs when their content has failed validation. Set this to a border style and thickness, e.g. solid 2px to enable borders. Set to none to disable borders but ensure that you have added styles to differentiate invalid from valid inputs.
    // --ag-input-border-color-invalid
    // CSS color (e.g. `red` or `#fff`)
    // The color for the border around invalid inputs, if enabled with --ag-borders-input-invalid
    // --ag-borders-side-button
    // a CSS border style and size (e.g. `solid 1px` or `none`)
    // Draw borders around the vertical tabs on the side of the control panel. Set this to a border style and thickness, e.g. solid 1px to enable borders, or none to disable borders.
    // --ag-border-radius
    // CSS size (e.g. `0`, `4px` or `50%`)
    // Border radius applied to many elements such as dialogs and form widgets
    // --ag-row-border-color
    // CSS color (e.g. `red` or `#fff`)
    // Colour of the border between grid rows, or transparent to display no border
    // --ag-invalid-color
    // CSS color (e.g. `red` or `#fff`)
    // The color applied to form elements in an invalid state
    // --ag-input-disabled-border-color
    // CSS color (e.g. `red` or `#fff`)
    // The border around disabled text inputs
    // --ag-input-disabled-background-color
    // CSS color (e.g. `red` or `#fff`)
    // The background colour of disabled text inputs
    // --ag-checkbox-background-color
    // CSS color (e.g. `red` or `#fff`)
    // the background of an checkbox
    // --ag-checkbox-border-radius
    // CSS size (e.g. `0`, `4px` or `50%`)
    // edge rounding a checkboxes
    // --ag-checkbox-checked-color
    // CSS color (e.g. `red` or `#fff`)
    // color of the checkbox-checked icon that is shown in a checked checkbox
    // --ag-checkbox-unchecked-color
    // CSS color (e.g. `red` or `#fff`)
    // color of the checkbox-unchecked icon that is shown in an unchecked checkbox
    // --ag-checkbox-indeterminate-color
    // CSS color (e.g. `red` or `#fff`)
    // color of the checkbox-indeterminate icon that is shown in an indeterminate checkbox
    // --ag-toggle-button-border-width
    // CSS size (e.g. `0`, `4px` or `50%`)
    // size of the toggle button outer border
    // --ag-toggle-button-on-border-color
    // CSS color (e.g. `red` or `#fff`)
    // colour of the toggle button outer border in its 'on' state
    // --ag-toggle-button-off-border-color
    // CSS color (e.g. `red` or `#fff`)
    // colour of the toggle button's outer border in its 'off' state
    // --ag-toggle-button-on-background-color
    // CSS color (e.g. `red` or `#fff`)
    // colour of the toggle button background in its 'on' state
    // --ag-toggle-button-off-background-color
    // CSS color (e.g. `red` or `#fff`)
    // colour of the toggle button background in its 'off' state
    // --ag-toggle-button-switch-background-color
    // CSS color (e.g. `red` or `#fff`)
    // colour of the toggle button switch (the bit that slides from left to right)
    // --ag-toggle-button-switch-border-color
    // CSS color (e.g. `red` or `#fff`)
    // border colour of the toggle button switch (the bit that slides from left to right)
    // --ag-toggle-button-width
    // CSS size (e.g. `0`, `4px` or `50%`)
    // width of the whole toggle button component
    // --ag-toggle-button-height
    // CSS size (e.g. `0`, `4px` or `50%`)
    // height of the whole toggle button component
    // --ag-input-focus-box-shadow
    // CSS box-shadow value (e.g. `0 5px 10px black`)
    // box shadow around focussed inputs
    // --ag-input-focus-border-color
    // CSS color (e.g. `red` or `#fff`)
    // Colour of the border around focussed inputs. Set to var(--ag-input-border-color) if you do not want to change the border colour on focus.
    // --ag-minichart-selected-chart-color
    // CSS color (e.g. `red` or `#fff`)
    // Color of border around selected chart style
    // --ag-minichart-selected-page-color
    // CSS color (e.g. `red` or `#fff`)
    // Color of dot representing selected page of chart styles
    // --ag-grid-size
    // CSS size (e.g. `0`, `4px` or `50%`)
    // grid-size is the main control for affecting how tightly data and UI elements are packed together. All padding and spacing in the grid is defined as a multiple of grid-size, so increasing it will make most components larger by increasing their internal white space while leaving the size of text and icons unchanged.
    // --ag-icon-size
    // CSS size (e.g. `0`, `4px` or `50%`)
    // The size of square icons and icon-buttons
    // --ag-widget-container-horizontal-padding
    // CSS size (e.g. `0`, `4px` or `50%`)
    // The horizontal padding of containers that contain stacked widgets, such as menus and tool panels
    // --ag-widget-container-vertical-padding
    // CSS size (e.g. `0`, `4px` or `50%`)
    // The vertical padding of containers that contain stacked widgets, such as menus and tool panels
    // --ag-widget-horizontal-spacing
    // CSS size (e.g. `0`, `4px` or `50%`)
    // The horizontal spacing between widgets in containers that contain horizontally stacked widgets such as the column groups header component.
    --ag-widget-vertical-spacing:0;
    // CSS size (e.g. `0`, `4px` or `50%`)
    // The vertical spacing between widgets in containers that contain vertically stacked widgets
    --ag-cell-horizontal-padding:5px;
    // CSS size (e.g. `0`, `4px` or `50%`)
    // Horizontal padding for grid and header cells (vertical padding is not set explicitly, but inferred from row-height / header-height
    // --ag-cell-widget-spacing
    // CSS size (e.g. `0`, `4px` or `50%`)
    // Horizontal spacing between widgets inside cells (e.g. row group expand buttons and row selection checkboxes)
    // --ag-row-height
    // CSS size (e.g. `0`, `4px` or `50%`)
    // Height of grid rows
    --ag-header-height:30px;
    // CSS size (e.g. `0`, `4px` or `50%`)
    // Height of header rows
    // --ag-list-item-height
    // CSS size (e.g. `0`, `4px` or `50%`)
    // Height of items in lists (example of lists are dropdown select inputs and column menu set filters)
    --ag-header-column-separator-display: none;
    // CSS display value - `block` to show or `none` to hide
    // Whether to display the header column separator - a vertical line that displays between every header cell
    // --ag-header-column-separator-height
    // CSS size (e.g. `0`, `4px` or `50%`)
    // Height of the header column separator. Percentage values are relative to the header height.
    // --ag-header-column-separator-width
    // CSS size (e.g. `0`, `4px` or `50%`)
    // Width of the header column separator
    // --ag-header-column-separator-color
    // CSS color (e.g. `red` or `#fff`)
    // Colour of the header column separator
    // --ag-header-column-resize-handle-display
    // CSS display value - `block` to show or `none` to hide
    // Whether to show the header column resize handle - a vertical line that displays only between resizeable header columns, indicating where to drag in order to resize the column.
    // --ag-header-column-resize-handle-height
    // CSS size (e.g. `0`, `4px` or `50%`)
    // Height of the header resize handle. Percentage values are relative to the header height.
    // --ag-header-column-resize-handle-width
    // CSS size (e.g. `0`, `4px` or `50%`)
    // Width of the header resize handle.
    // --ag-header-column-resize-handle-color
    // CSS color (e.g. `red` or `#fff`)
    // Colour of the header resize handle
    // --ag-column-select-indent-size
    // CSS size (e.g. `0`, `4px` or `50%`)
    // How much to indent child columns in the column tool panel relative to their parent
    // --ag-row-group-indent-size
    // CSS size (e.g. `0`, `4px` or `50%`)
    // How much to indent child rows in the grid relative to their parent row
    // --ag-filter-tool-panel-group-indent
    // CSS size (e.g. `0`, `4px` or `50%`)
    // How much to indent child columns in the filters tool panel relative to their parent
    // --ag-tab-min-width
    // CSS size (e.g. `0`, `4px` or `50%`)
    // Minimum width of a tabbed menu (usd in charts)
    // --ag-menu-min-width
    // CSS size (e.g. `0`, `4px` or `50%`)
    // Minimum width of a menu that is not tabbed
    // --ag-side-bar-panel-width
    // CSS size (e.g. `0`, `4px` or `50%`)
    // Width of the sidebar that contains the columns and filters tool panels
    // --ag-font-family
    // CSS font-family value (e.g. `font-family: `'Gill Sans', sans-serif`)
    // Font family used for all text
    // --ag-font-size
    // CSS size (e.g. `0`, `4px` or `50%`)
    // Default font size for text in the grid
    // --ag-icon-font-family
    // CSS size (e.g. `0`, `4px` or `50%`)
    // The icon font used by the grid.
    // --ag-card-radius
    // CSS size (e.g. `0`, `4px` or `50%`)
    // cards are elements that float above the UI
    // --ag-card-shadow
    // CSS box-shadow value (e.g. `0 5px 10px black`)
    // the default card shadow applies to simple cards like column drag indicators and text editors
    // --ag-popup-shadow
    // CSS box-shadow value (e.g. `0 5px 10px black`)
}

.ag-theme-material-dark {
  font-size: inherit;  
  font-family: inherit;
  .ag-row {
    //border-left: var(--ag-borders-row) var(--ag-row-border-color);
    //border-right: var(--ag-borders-row) var(--ag-row-border-color);
    border-left-width: 1px;
    border-right-width: 1px;
    cursor: pointer;
  }

  .ag-invisible {
    display: none !important;
  }

 // .ag-header {
 //   @apply border;
 // }

  .ag-header-row {
    @apply shadow;
    //@apply bg-gray-50;
    //@apply text-secondary;
    @apply font-semibold;
    @apply text-md;
    height: 28px;
  }
  .ag-header-row-column-filter {
    background-color: transparent;
  }
  .ag-watermark {
    display: none;
  }

  .ag-cell-focus {
    border-width: 0px !important;
  }

  .ag-cell-range-selected {
    border-width: 0px !important;
  }

  button.ag-side-button-button {
    min-height: auto !important;
  }
  --ag-line-height: 28px;
  --ag-row-height: 28px;
  --ag-font-family: inherit;
  --ag-font-size: inherit;
  //--ag-material-primary-color: var(--fuse-primary);
  --ag-widget-vertical-spacing:0;
  --ag-cell-horizontal-padding:5px;
  --ag-header-height:30px;
  --ag-header-column-separator-display: none;
  //--ag-background-color: #121212ff;
  --ag-background-color:rgb(30 41 59 / var(--tw-bg-opacity)) !important;
  --ag-row-border-color: rgba(241,245,249, .12);
  --ag-odd-row-background-color: var(--ag-background-color);
  --ag-row-hover-color: var(--fuse-bg-hover);
  --ag-foreground-color: #fff;
  --ag-disabled-foreground-color: rgb(100, 116, 139);
  --ag-header-background-color: var(--ag-background-color) !important;
  --ag-grid-size: 6px;
  --ag-input-focus-border-color: rgb(203 213 225 / var(--tw-border-opacity)) !important;
}

input[class^=ag-]:not([type]), .ag-theme-material input[class^=ag-][type=text], .ag-theme-material input[class^=ag-][type=number], .ag-theme-material input[class^=ag-][type=tel], .ag-theme-material input[class^=ag-][type=date], .ag-theme-material input[class^=ag-][type=datetime-local], .ag-theme-material textarea[class^=ag-] {
  padding-bottom: 0px;
  padding-top: 1px;
}
/*
.ag-theme-balham {
  @include ag-theme-balham((
      // use theme parameters where possible
      balham-active-color: #0091ea,// var(--fuse-primary),
      odd-row-background-color: white,
      row-hover-color: var(--fuse-primary-50),
      row-border-color: #d9dcde,
      selected-row-background-color: var(--fuse-primary-50),
      borders: false,
      font-family: inherit,
      font-size: 11px
    ));
  
  .ag-row {
    border-width: 0px;
    border-bottom-width: 1px;
  }

  .ag-row-focus {
    background-color: var(--fuse-primary-300);
    color: white;
    font-weight: bold;
  }

  .ag-watermark {
    display: none;
  }

  .ag-invisible {
    display: none !important;
  }

  .ag-cell-editor {
    color: var(--fuse-primary) !important;
    font-weight: bold;
  }
  .ag-cell-inline-editing {
    border-color: var(--ag-header-background-color, #f5f7f7) !important;
  }

  .ag-row-inline-editing {
    background-color: var(--ag-header-background-color, #f5f7f7) !important;
    color: #495057 !important;
    font-weight: initial !important;
  }

  .ag-cell-inline-editing .kd-field-editable {
    background-color: white !important;
  }

  // .ag-cell-not-inline-editing {
  //   color: var(--fuse-primary) !important;
  // }
}
*/

/*
// Colour of text and icons in primary UI elements like menus
foreground-color: #000,

// Colour of text in grid cells
data-color: ag-derived(foreground-color),

// Colour of text and icons in UI elements that need to be slightly less emphasised to avoid distracting attention from data
secondary-foreground-color: ag-derived(foreground-color),

// Colour of text and icons in the header
header-foreground-color: ag-derived(secondary-foreground-color),

// Color of elements that can't be interacted with because they are in a disabled state
disabled-foreground-color: ag-derived(foreground-color, $opacity: 0.5),

// Background colour of the grid
background-color: #fff,

// Background colour for all headers, including the grid header, panels etc
header-background-color: null,

// Background colour for second level headings within UI components
subheader-background-color: null,

// Background colour for toolbars directly under subheadings (as used in the chart settings menu)
subheader-toolbar-background-color: null,

// Background for areas of the interface that contain UI controls, like tool panels and the chart settings menu
control-panel-background-color: null,

// Background color of selected rows in the grid and in dropdown menus
selected-row-background-color: ag-derived(background-color, $mix: foreground-color 25%),

// Background colour applied to every other row or null to use background-color for all rows
odd-row-background-color: null,

// Background color of the overlay shown over the grid when it is covered by an overlay, e.g. a data loading indicator.
modal-overlay-background-color: ag-derived(background-color, $opacity: 0.66),

// Background color when hovering over rows in the grid and in dropdown menus, or null for no rollover effect (note - if you want a rollover on one but not the other, set to null and use CSS to achieve the rollover)
row-hover-color: null,

// Background color when hovering over columns
column-hover-color: null,

// Color to draw around selected cell ranges
range-selection-border-color: ag-derived(foreground-color),

// Background colour of selected cell ranges. By default, setting this to a semi-transparent color (opacity of 0.1 to 0.5 works well) will generate appropriate values for the range-selection-background-color-{1..4} colours used when multiple ranges overlap.
// NOTE: if setting this value to a CSS variable, and your app supports overlapping range selections, also set range-selection-background-color-{1..4}.
range-selection-background-color: ag-derived(range-selection-border-color, $opacity: 0.2),

// These 4 parameters are used for fine-grained control over the background color used when 1, 2, 3 or 4 ranges overlap.
range-selection-background-color-1: ag-derived(range-selection-background-color),
range-selection-background-color-2: ag-derived(range-selection-background-color, $self-overlay: 2),
range-selection-background-color-3: ag-derived(range-selection-background-color, $self-overlay: 3),
range-selection-background-color-4: ag-derived(range-selection-background-color, $self-overlay: 4),

// Background colour to apply to a cell range when it is copied from or pasted into
range-selection-highlight-color: ag-derived(range-selection-border-color),

// Colour and thickness of the border drawn under selected tabs, including menus and tool panels
selected-tab-underline-color: ag-derived(range-selection-border-color),
selected-tab-underline-width: 0,
selected-tab-underline-transition-speed: null,

// Background colour for cells that provide categories to the current range chart
range-selection-chart-category-background-color: rgba(#00FF84, 0.1),

// Background colour for cells that provide data to the current range chart
range-selection-chart-background-color: rgba(#0058FF, 0.1),

// Rollover colour for header cells
header-cell-hover-background-color: null,

// Colour applied to header cells when the column is being dragged to a new position
header-cell-moving-background-color: ag-derived(header-cell-hover-background-color),

// Colour to apply when a cell value changes and enableCellChangeFlash is enabled
value-change-value-highlight-background-color: rgba(#16A085, 0.5),

// Colours to apply when a value increases or decreases in an agAnimateShowChangeCellRenderer cell
value-change-delta-up-color: #43a047,
value-change-delta-down-color: #e53935,

// Colour for the "chip" that repersents a column that has been dragged onto a drop zone
chip-background-color: null,

// By default, color variables can be overridden at runtime by CSS variables, e.g.
// background-color can be overridden with the CSS var --ag-background-color. Pass true
// to disable this behaviour.
suppress-css-var-overrides: false,

//
// BORDERS
//

// Draw borders around most UI elements
borders: true,

// Draw the few borders that are critical to UX, e.g. between headers and rows.
borders-critical: ag-derived(borders),

// Draw decorative borders separating UI elements within components
borders-secondary: ag-derived(borders),

// Draw borders around sidebar tabs so that the active tab appears connected to the current tool panel
borders-side-button: ag-derived(borders),

side-bar-panel-width: 200px,

border-radius: 0px,

// Colour for border around major UI components like the grid itself, headers, footers and tool panels
border-color: ag-derived(background-color, $mix: foreground-color 25%),

// Colour for borders used to separate elements within a major UI component
secondary-border-color: ag-derived(border-color),

// Colour of the border between grid rows, or null to display no border
row-border-color: ag-derived(secondary-border-color),

// Default border for cells. This can be used to specify the border-style and border-color properties e.g. `dashed red` but the border-width is fixed at 1px.
cell-horizontal-border: solid transparent,

// Separator between columns in the header. Displays between all header cells For best UX, use either this or header-column-resize-handle but not both
header-column-separator: false,
header-column-separator-height: 100%,
header-column-separator-width: 1px,
header-column-separator-color: ag-derived(border-color, $opacity: 0.5),

// Visible marker for resizeable columns. Displays in the same position as the column separator, but only when the column is resizeable. For best UX, use either this or header-column-separator but not both
header-column-resize-handle: false,
header-column-resize-handle-height: 50%,
header-column-resize-handle-width: 1px,
header-column-resize-handle-color: ag-derived(border-color, $opacity: 0.5),

//
// INPUTS
//

// Suppress styling of checkbox/radio/range input elements. If you want to style these yourself, set this to true. If you only want to disable styling for some kinds of input, you can set this to true and e.g. @include ag-native-inputs((checkbox: false)) which will emit styles for all kinds of input except checkboxes.
suppress-native-widget-styling: false,

input-border-color: null,
input-disabled-border-color: ag-derived(input-border-color, $opacity: 0.3),
input-disabled-background-color: null,

checkbox-background-color: null,
checkbox-border-radius: ag-derived(border-radius),
checkbox-checked-color: ag-derived(foreground-color),
checkbox-unchecked-color: ag-derived(foreground-color),
checkbox-indeterminate-color: ag-derived(checkbox-unchecked-color),

toggle-button-off-border-color: ag-derived(checkbox-unchecked-color),
toggle-button-off-background-color: ag-derived(checkbox-unchecked-color),
toggle-button-on-border-color: ag-derived(checkbox-checked-color),
toggle-button-on-background-color: ag-derived(checkbox-checked-color),
toggle-button-switch-background-color: ag-derived(background-color),
toggle-button-switch-border-color: ag-derived(toggle-button-off-border-color),
toggle-button-border-width: 1px,
toggle-button-height: ag-derived(icon-size),
toggle-button-width: ag-derived(toggle-button-height, $times: 2),

input-focus-box-shadow: null,
input-focus-border-color: null,

// CHART SETTINGS

// Color of border around selected chart style
minichart-selected-chart-color: ag-derived(checkbox-checked-color),
// Color of dot representing selected page of chart styles
minichart-selected-page-color: ag-derived(checkbox-checked-color),


//
// SIZING / PADDING / SPACING
//

// grid-size is the main control for affecting how tightly data and UI elements are packed together. All padding and spacing in the grid is defined as a multiple of grid-size, so increasing it will make most components larger by increasing their internal white space while leaving the size of text and icons unchanged.
grid-size: 4px,

// The size of square icons and icon-buttons
icon-size: 12px,

// These 4 variables set the padding around and spacing between widgets in "widget containers" which are parts of the UI that contain many related widgets, like the set filter menu, charts settings tabs etc.
widget-container-horizontal-padding: ag-derived(grid-size, $times: 1.5),
widget-container-vertical-padding: ag-derived(grid-size, $times: 1.5),
widget-horizontal-spacing: ag-derived(grid-size, $times: 1.5),
widget-vertical-spacing: ag-derived(grid-size),

// Horizontal padding for grid and header cells (vertical padding is not set explicitly, but inferred from row-height / header-height
cell-horizontal-padding: ag-derived(grid-size, $times: 3),

// Horizontal spacing between widgets inside cells (e.g. row group expand buttons and row selection checkboxes)
cell-widget-spacing: ag-derived(cell-horizontal-padding),

// Height of grid rows
row-height: ag-derived(grid-size, $times: 6, $plus: 1),

// Height of header rows
header-height: ag-derived(row-height),

// Height of items in lists (example of lists are dropdown select inputs and column menu set filters)
list-item-height: ag-derived(grid-size, $times: 5),

// How much to indent child columns in the column tool panel relative to their parent
column-select-indent-size: ag-derived(grid-size, $plus: icon-size),

// How much to indent child rows in the grid relative to their parent row
row-group-indent-size: ag-derived(cell-widget-spacing, $plus: icon-size),

// How much to indent child columns in the filters tool panel relative to their parent
filter-tool-panel-group-indent: 16px,

// Cause tabs to stretch across the full width of the tab panel header
full-width-tabs: false,

// Fonts
font-family: ("Helvetica Neue", sans-serif),
font-size: 14px,

// The name of the font family you're using
icon-font-family: $ag-theme-base-icon-font-family, // this var exported by ag-theme-base-font-vars.scss

// A URI (data: URI or web URL) to load the icon font from. NOTE: if your icon font is already loaded in the app's HTML page, set this to null to avoid embedding unnecessry font data in the compiled theme.
icons-data: $ag-theme-base-icons-data,             // this var exported by ag-theme-base-font-vars.scss
icons-font-codes: $ag-theme-base-icons-font-codes, // this var exported by ag-theme-base-font-vars.scss

// cards are elements that float above the UI
card-radius: ag-derived(border-radius),

// the default card shadow applies to simple cards like column drag indicators and text editors
card-shadow: none,

// override the shadow for popups - cards that contain complex UI, like menus and charts
popup-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3)
*/

.ag-root-wrapper.ag-layout-normal {
  min-height: 200px;
}

.ag-ltr .ag-floating-filter-button {
  margin-left: 3px;
}

.ag-kedi-vertical-lines {
  --ag-header-column-separator-display: block;
  --ag-cell-horizontal-border: solid 1px var(--ag-row-border-color);
}

.ag-kedi-odd-lines {
  --ag-odd-row-background-color: color-mix(in srgb, var(--fuse-primary) 5%, transparent);
}

.ag-kedi-headers-hidden .ag-header {
  display: none;
}

.ag-theme-material .ag-cell-inline-editing, .ag-theme-material-dark .ag-cell-inline-editing, .ag-theme-material-auto-dark .ag-cell-inline-editing {
    padding: 0px;
    height: var(--ag-row-height);
    border-radius: 0px;
}

.kd-grid-inline-editor .mat-mdc-form-field.mat-form-field-appearance-fill.fuse-mat-dense .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
  min-height: 26px;
}

.kd-grid-inline-editor .mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
  padding-left: 4px;
}

.kd-grid-inline-editor .mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper {
  border-width: 0px;
}