/* Order */

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
// body {
//   font-family: 'Inter var' !important
// }
table{
  width: 100%;
}

.cdk-global-overlay-wrapper, .cdk-overlay-container {
  z-index: 99999 !important;
}


.md-drppicker.double {
  min-width: 600px !important;
}

.md-drppicker {
  height: 300px !important;
  overflow-y: auto !important;
}

.md-drppicker .ranges {
  height: 100% !important;
  overflow-y: auto !important;
}

mat-form-field .mdc-text-field {
  z-index: 20 !important;
  overflow: visible !important;
}

.document-editor {
  border: 1px solid var(--ck-color-base-border);
  border-radius: var(--ck-border-radius);

  /* Set vertical boundaries for the document editor. */
  max-height: 700px;

  /* This element is a flex container for easier rendering. */
  display: flex;
  flex-flow: column nowrap;
}

.document-editor__toolbar {
  /* Make sure the toolbar container is always above the editable. */
  z-index: 1;

  /* Create the illusion of the toolbar floating over the editable. */
  box-shadow: 0 0 5px hsla( 0,0%,0%,.2 );

  /* Use the CKEditor CSS variables to keep the UI consistent. */
  border-bottom: 1px solid var(--ck-color-toolbar-border);
}

/* Adjust the look of the toolbar inside of the container. */
.document-editor__toolbar .ck-toolbar {
  border: 0;
  border-radius: 0;
}

/* Make the editable container look like the inside of a native word processor app. */
.document-editor__editable-container {
  padding: calc( 2 * var(--ck-spacing-large) );
  background: var(--ck-color-base-foreground);

  /* Make it possible to scroll the "page" of the edited content. */
  overflow-y: scroll;
}

.document-editor__editable-container .document-editor__editable .ck-editor__editable {
  /* Set the dimensions of the "page". */
  width: auto !important;
  min-height: 21cm;
  margin-top:25px !important;

  /* Keep the "page" off the boundaries of the container. */
  padding: 1cm 2cm 2cm;

  border: 1px hsl( 0,0%,82.7% ) solid;
  border-radius: var(--ck-border-radius);
  background: white;

  /* The "page" should cast a slight shadow (3D illusion). */
  box-shadow: 0 0 5px hsla( 0,0%,0%,.1 );

  /* Center the "page". */
  margin: 0 auto;
}

/* Override the page's width in the "Examples" section which is wider. */
.main__content-wide .document-editor__editable-container .document-editor__editable.ck-editor__editable {
  width: 18cm;
}

/* Set the default font for the "page" of the content. */
.document-editor .ck-content,
.document-editor .ck-heading-dropdown .ck-list .ck-button__label {
  font: 16px/1.6 inherit;//"Helvetica Neue", Helvetica, Arial, sans-serif;
}

/* Adjust the headings dropdown to host some larger heading styles. */
.document-editor .ck-heading-dropdown .ck-list .ck-button__label {
  line-height: calc( 1.7 * var(--ck-line-height-base) * var(--ck-font-size-base) );
  min-width: 6em;
}

/* Scale down all heading previews because they are way too big to be presented in the UI.
Preserve the relative scale, though. */
.document-editor .ck-heading-dropdown .ck-list .ck-heading_heading1 .ck-button__label,
.document-editor .ck-heading-dropdown .ck-list .ck-heading_heading2 .ck-button__label {
  transform: scale(0.8);
  transform-origin: left;
}

/* Set the styles for "Heading 1". */
.document-editor .ck-content h2,
.document-editor .ck-heading-dropdown .ck-heading_heading1 .ck-button__label {
  font-size: 2.18em;
  font-weight: normal;
}

.document-editor .ck-content h2 {
  line-height: 1.37em;
  padding-top: .342em;
  margin-bottom: .142em;
}

/* Set the styles for "Heading 2". */
.document-editor .ck-content h3,
.document-editor .ck-heading-dropdown .ck-heading_heading2 .ck-button__label {
  font-size: 1.75em;
  font-weight: normal;
  color: hsl( 203, 100%, 50% );
}

.document-editor .ck-heading-dropdown .ck-heading_heading2.ck-on .ck-button__label {
  color: var(--ck-color-list-button-on-text);
}

/* Set the styles for "Heading 2". */
.document-editor .ck-content h3 {
  line-height: 1.86em;
  padding-top: .171em;
  margin-bottom: .357em;
}

/* Set the styles for "Heading 3". */
.document-editor .ck-content h4,
.document-editor .ck-heading-dropdown .ck-heading_heading3 .ck-button__label {
  font-size: 1.31em;
  font-weight: bold;
}

.document-editor .ck-content h4 {
  line-height: 1.24em;
  padding-top: .286em;
  margin-bottom: .952em;
}

/* Make the block quoted text serif with some additional spacing. */
.document-editor .ck-content blockquote {
  font-family: Georgia, serif;
  margin-left: calc( 2 * var(--ck-spacing-large) );
  margin-right: calc( 2 * var(--ck-spacing-large) );
}

@media only screen and (max-width: 960px) {
  /* Because on mobile 2cm paddings are to big. */
  .document-editor__editable-container .document-editor__editable.ck-editor__editable {
      padding: 1.5em;
  }
}

@media only screen and (max-width: 1200px) {
  .main__content-wide .document-editor__editable-container .document-editor__editable.ck-editor__editable {
      width: 100%;
  }
}

/* Style document editor a'ka Google Docs.*/
@media only screen and (min-width: 1360px) {
  .main .main__content.main__content-wide {
      padding-right: 0;
  }
}

@media only screen and (min-width: 1600px) {
  .main .main__content.main__content-wide {
      width: 24cm;
  }

  .main .main__content.main__content-wide .main__content-inner {
      width: auto;
      margin: 0 50px;
  }

  /* Keep "page" look based on viewport width. */
  .main__content-wide .document-editor__editable-container .document-editor__editable.ck-editor__editable {
      width: 60%;
  }
}

.ck.ck-toolbar {
  top: 0px !important;
  position: sticky !important;
}

.ck.ck-toolbar.ck-toolbar_grouping>.ck-toolbar__items {
  flex-wrap: wrap !important;
}

.ck.ck-editor__top .ck-sticky-panel .ck-toolbar {
  border-bottom-width: thin !important;
}

.ck-html {
  .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
    border-color: transparent;
  }
  .ck.ck-editor__editable_inline>:first-child {
    margin-top: auto;
  }
  .ck.ck-editor__editable_inline>:last-child {
    margin-bottom: auto;
  }
  .ck.ck-editor__editable_inline {
    padding-left: 0;
  }
}

.ngx-otp-input {
  .ngx-otp-input-filled {
    background-color:rgba(245, 222, 179, 0.75);
  }
  .ngx-otp-input-box {
    margin: 0 2px !important;
  }
  border-width: 1px !important;
  border-radius: 6px !important;
  border-color: inherit !important;
}

mat-dialog-container {
  resize: both;
  overflow: auto;
}

mat-dialog-container::-webkit-resizer {
  content: '\25AE';
}