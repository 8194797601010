// mixins shared between the our themes and the public API

// Apply styles to all text fields. Usage:
// @include ag.text-input {
//     border: solid dashed red;
// }
@mixin text-input {
    input[class^='ag-']:not([type]),
    input[class^='ag-'][type='text'],
    input[class^='ag-'][type='number'],
    input[class^='ag-'][type='tel'],
    input[class^='ag-'][type='date'],
    input[class^='ag-'][type='datetime-local'],
    textarea[class^='ag-'] {
        @content;
    }
}
