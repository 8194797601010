@layer beforethen {
  centered-layout>div.flex>div.bg-transparent>div.bg-transparent {
    background-color: transparent !important;
  }
}

.ihe-product {
  height: 25rem;
  width: 25rem;
}

.ihe-product .ihe-product-image-big {
  width: 24rem;
  height: 24rem;
  border-width: 0px;
  padding: 5px;
}
.ihe-product-image-thumb {
  width: 4rem;
  height: 4rem;
  border-width: 0px !important;
  padding: 0px;
  object-fit: contain;
}
.ihe-product-desc p {
  padding-bottom: 1.5rem;
}

.ihe-product-desc h2 {
  padding-bottom: 1.5rem;
  font-weight: bolder;
}

.ihe-product-warning {
  font-weight: bolder;
  color: red;
}

.ihe-basket-box {
  border-width: 2px;
  border-color: var(--fuse-primary-400);
}

.ihe-order-box {
  border-width: 2px;
  border-color: var(--fuse-secondary);
}

.ihe-basket-counter {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  flex-shrink: 0;
  -webkit-box-align: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  overflow: hidden;
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  flex-direction: column-reverse;
}

.ihe-basket-counter .ihe-basket-counter-action {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-shrink: 0;
  background: white;
  width: 32px;
  height: 32px;
  border-radius: 0px;
  color: var(--fuse-primary);
  user-select: none;
  border: 0px;
  padding: 0px;
  overflow: initial;
}

.ihe-basket-counter .ihe-basket-counter-count {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-shrink: 0;
  background: var(--fuse-primary);
  width: 32px;
  height: 32px;
  border-radius: 0px;
  color: white;
  user-select: none;
  border: 0px;
  padding: 0px;
  overflow: initial;
}

.ihe-basket-counter .ihe-basket-counter-action:first-child {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.ihe-basket-counter .ihe-basket-counter-action:last-child {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.ihe-basket-counter.trade-down {
  top: auto !important;
  bottom: 2px;
}

.ihe-product-detail div:last-child {
  grid-column: span 2;
}

.ihe-product-desc ul {
  list-style: circle;
  margin: 0;
  padding-left: 25px;
}

.ihe-dashed-box {
  border-style: dashed !important;
  border: 1px;
  border-color: var(--fuse-primary-300);
}

.ihe-box {
  border-style: 1px !important;
  border: 1px;
  border-color: var(--fuse-primary-300);
}

.ihe-suffix-box > .mat-suffix-box {
  background-color: var(--fuse-primary) !important;
  cursor: pointer;
  padding-right: 0px;
}

.contract-text td {
  border: 1px solid !important;
}

// BANKA KARTI
.credit-card-box-cover {
  width: 507px;
  height: 300px;
  padding-top: 2.4rem;
  margin-left: 2.5rem;
  //position: absolute;
  //right: 0;
  //top: 0;
  //perspective: 1000px;
}

.credit-card-box {
  width: 297px;
  height: 208px;
  border-radius: 7.4px;
  box-shadow: 0 8px 32px 0 rgb(0 0 0 / 8%);
  background-color: white;
  transform-style: preserve-3d;
  transition: transform 0.6s;
  -webkit-transform: 0.6s;
}

.credit-card-box-front {
  opacity: 0.2s;
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
}

.credit-card-bank-logo {
  width: 123px;
  height: 81px;
  position: absolute;
  top: 24px;
  left: 24px;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  justify-content: space-around;
  -webkit-box-align: start;
  align-items: flex-start;
}

.credit-card-scheme-logo {
  width: 90px;
  height: 35px;
  position: absolute;
  display: flex;
  bottom: 22px;
  right: 8px;
}

.credit-card-info {
  position: absolute;
  bottom: 16px;
  left: 32px;
}

.credit-card-chip {
  position: absolute;
  top: 31px;
  right: 31px;
}

.ihe-order-timeline {
}

.ihe-order-timeline-marker {
  display: flex;
  width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  border-radius: 50%;
  z-index: 1;
}

.cookie-consent.visible {
  opacity: 1;
  visibility: visible;
}

.cookie-consent {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 25px 17px;
  max-width: 800px;
  position: fixed;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.2s;
  opacity: 0;
  visibility: hidden;
  z-index: 10000;
  width: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: var(--fuse-primary);
}

.cookie-consent .cookie-consent-text {
  font-size: 12px;
  color: #53565a;
  line-height: 18px;
}

.cookie-consent .cookie-consent-text a {
  font-weight: bold;
  color: var(--fuse-primary);
}

.cookie-consent .cookie-consent-text a:hover {
  font-weight: bold;
  color: var(--fuse-primary-300);
}

.cookie-consent .cookie-consent-btn {
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  background-color: #9a3324;
  padding: 11px 19px;
  text-transform: uppercase;
  min-width: 175px;
  line-height: 18px;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-base {
  background-color: #53565a !important;
}

.footer-base-dark {
  background-color: #53565a !important;
}

.kedi-auth-body {
  --tw-bg-opacity: 1;
  background-color: rgba(143, 31, 31, var(--tw-bg-opacity));
}

.kedi-auth.text-weak {
  color: rgb(205, 105, 105) !important;
}

.kedi-main-logo {
  width: 14rem !important;
  height: 5rem !important;
  object-fit: cover;
}

.kedi-main-logo-dark {
  width: 14rem !important;
  height: 5rem !important;
  object-fit: cover;
}

.kedi-main-logo.kedi-layout-centered {
  margin-left: -2rem;
}

.kedi-main-logo-dark.kedi-layout-centered {
  margin-left: -2rem;
}

.footer-panel {
  padding-bottom: 10px;
}

.footer-panel p {
  padding-bottom: 10px;
}

.footer-panel li {
  padding-bottom: 10px;
}

.ihe-footer {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  width: 100% !important;
}

.ihe-footer .footer-catalog {
  min-height: 300px;
  background: url("/images/footer/katalog.jpg"),
    linear-gradient(0deg, #9a3324 0%, #4d1a12 100%);
  background-blend-mode: multiply;
  color: #fff;
  display: flex;
  padding-top: 44px;
  font-size: larger;
}

.ihe-footer .footer-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #53565a;
  row-gap: 47px;
}

.ihe-footer .footer-catalog .container {
  align-items: center;
  text-align: center;
}
.container {
  max-width: 1140px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.ihe-footer .footer-catalog h4 {
  font-size: 34px;
  font-weight: 700;
  margin-bottom: 12px;
}

.ihe-footer .footer-catalog p {
  max-width: 453px;
  margin-bottom: 54px;
  text-align: center;
  line-height: 22px;
}

.ihe-footer .footer-catalog .footer-catalog-btn {
  border: 1px solid #fff;
  height: 50px;
  font-size: 14px;
  font-weight: 600;
  width: 132px;
}

.btn {
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ihe-footer .footer-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #53565a;
  row-gap: 47px;
}

.ihe-footer .footer-container .container {
  row-gap: 48px;
}

.ihe-footer .footer-container .footer-top {
  display: flex;
  column-gap: 30px;
  padding-top: 23px;
}

.ihe-footer .footer-container .footer-bottom {
  display: grid;
  column-gap: 23px;
  grid-template-columns: repeat(5, 1fr);
  padding-bottom: 48px;
}

.ihe-footer .footer-container .footer-top .footer-top-logos {
  display: flex;
  column-gap: 17px;
}

.ihe-footer .footer-container .footer-top .footer-top-logos img {
  width: 100px;
  height: 70px;
  object-fit: contain;
  max-width: min-content;
}

.ihe-footer .footer-container .footer-top .footer-top-logos .logo-divider {
  height: 100%;
  display: block;
  width: 1px;
  background-color: #fff;
}

.ihe-footer .footer-container .footer-top .footer-top-address {
  color: #fff;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ihe-footer .footer-container .footer-top .footer-top-address p.title {
  font-weight: 700;
}

.ihe-footer .footer-container .footer-bottom .footer-bottom-nav {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.ihe-footer .footer-container .footer-bottom .footer-bottom-nav h5 {
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
}

.ihe-footer .footer-container .footer-bottom .footer-bottom-nav ul {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 5px;
}

.ihe-footer .footer-container .footer-bottom .footer-bottom-nav ul.social {
  flex-direction: row;
  align-items: center;
  column-gap: 15px;
  justify-content: flex-start;
}

.ihe-footer .footer-container .footer-bottom .footer-bottom-nav ul li a {
  font-size: 14px;
  color: #fff;
}

.ihe-footer
  .footer-container
  .footer-bottom
  .footer-bottom-nav
  ul.social
  .social-icon {
  display: block;
  height: 19px;
  width: 19px;
  background-repeat: no-repeat;
  background-position: center;
}

.ihe-footer
  .footer-container
  .footer-bottom
  .footer-bottom-nav
  ul.social
  .social-icon.facebook {
  background-image: url("/images/footer/facebook.svg");
}

.ihe-footer
  .footer-container
  .footer-bottom
  .footer-bottom-nav
  ul.social
  .social-icon.twitter {
  background-image: url("/images/footer/twitter.svg");
}

.ihe-footer
  .footer-container
  .footer-bottom
  .footer-bottom-nav
  ul.social
  .social-icon.instagram {
  background-image: url("/images/footer/instagram.svg");
}

.ihe-footer
  .footer-container
  .footer-bottom
  .footer-bottom-nav
  ul.social
  .social-icon.youtube {
  width: 27px;
  height: 19px;
  background-image: url("/images/footer/youtube.svg");
}

.ihe-footer
  .footer-container
  .footer-bottom
  .footer-bottom-nav
  ul.social
  .social-icon.linkedin {
  width: 23px;
  height: 22px;
  background-image: url("/images/footer/linkedin.svg");
}

.ihe-footer .footer-container .footer-bottom .footer-bottom-nav p {
  font-size: 11px;
  color: #fff;
  line-height: 16px;
}

.ihe-footer .footer-bottom-bar {
  display: flex;
  align-items: center;
  min-height: 60px;
  width: 100vw;
  background-color: #606366;
}

.ihe-footer .footer-bottom-bar .footer-bottom-bar-nav {
  display: flex;
  align-items: center;
}

.ihe-footer .footer-bottom-bar .footer-bottom-bar-nav ul {
  display: flex;
  align-items: center;
}

.ihe-footer .footer-bottom-bar .footer-bottom-bar-nav ul li:first-child {
  border-right: 1px solid #f2a900;
  padding-left: 0;
}

.ihe-footer .footer-bottom-bar .footer-bottom-bar-nav ul li {
  display: flex;
  align-items: center;
  padding: 3px 15px;
}

.ihe-footer .footer-bottom-bar .footer-bottom-bar-nav ul li a {
  font-size: 12px;
  color: #fff;
}

.ihe-footer .footer-bottom-bar ul li a {
  font-size: 14px;
  color: #fff;
}

.ihe-footer .footer-bottom-bar .footer-bottom-nav {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.ihe-footer .footer-bottom-bar ul.logos {
  flex-direction: row;
  align-items: center;
  column-gap: 15px;
  justify-content: flex-start;
}

.ihe-footer .footer-bottom-bar ul.logos .logos-icon {
  display: block;
  height: 19px;
  width: 19px;
  background-repeat: no-repeat;
  background-position: center;
}

.ihe-footer .footer-bottom-bar ul.logos .logos-icon.secure_shop {
  width: 27px;
  height: 19px;
  background-image: url("/images/ecommerce/shop/secure.svg");
}

.ihe-footer .footer-bottom-bar ul.logos .logos-icon.secure_3d {
  width: 27px;
  height: 19px;
  background-image: url("/images/ecommerce/shop/3d.png");
}

.ihe-footer .footer-bottom-bar ul.logos .logos-icon.secure_visa {
  width: 27px;
  height: 19px;
  background-image: url("/images/ecommerce/holder/visa.svg");
}

.ihe-footer .footer-bottom-bar ul.logos .logos-icon.secure_mastercard {
  width: 27px;
  height: 19px;
  background-image: url("/images/ecommerce/holder/mastercard.svg");
}

.ihe-footer .footer-bottom-bar ul.logos .logos-icon.secure_troy {
  width: 27px;
  height: 19px;
  background-image: url("/images/ecommerce/holder/troy.svg");
}

.p-sidebar .p-sidebar-header {
  display: none !important;
}

.p-sidebar-content {
  background-color: black;
  height: min-content;
}

.carousel-indicators {
  display: none;
}
