@layer kedi {
  body .p-component { 
    font-family: 'Inter var';
    font-size: 0.875rem;
  }

  body .p-input-group-label { 
    font-family: 'Inter var';
    font-size: 0.875rem;
  }

  .p-inputgroup {
    box-shadow: none;
  }

  .p-inputgroup > .p-inputwrapper:last-child > .p-autocomplete > .p-inputtext {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 3px !important;
    border-bottom-left-radius: 3px !important;
  }

  .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input {
    font-size: inherit;
  }

  body .p-datepicker {
    font-family: 'Inter var';
    font-size: 0.875rem;
  }

  .p-carousel .p-carousel-content .p-carousel-prev, .p-carousel .p-carousel-content .p-carousel-next  {
    display: none;
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-content {
    padding: 7px !important;
  }
  /* ----------------------------------------------------------------------------------------------------- */
  /*  @ Primeng overrides
  /* ----------------------------------------------------------------------------------------------------- */

  .p-button {
    background-color: var(--fuse-primary);
    border-top-color: var(--fuse-primary);
    border-left-color: var(--fuse-primary);
    border-right-color: var(--fuse-primary);
    border-bottom-color: var(--fuse-primary);
  }

  .p-button:enabled:hover {
    background: var(--fuse-primary-300);
    //background: #0d89ec;
    border-color: var(--fuse-primary-300);
  }
  .p-button:enabled:active {
    background: var(--fuse-primary-400);
    //background: #0b7ad1;
    border-color: var(--fuse-primary-400);
    //border-color: #0b7ad1;
  }
  .p-button.p-button-outlined {
    color: var(--fuse-primary);
  }
  .p-button.p-button-outlined:enabled:hover {
    color: var(--fuse-primary);
  }
  .p-button.p-button-outlined:enabled:active {
    color: var(--fuse-primary);
  }    
  .p-button.p-button-text {
    color: var(--fuse-primary);
  }
  .p-button.p-button-text:enabled:hover {
    color: var(--fuse-primary);
  }
  .p-button.p-button-text:enabled:active {
    color: var(--fuse-primary);
  }  
  .p-button:focus {
    //box-shadow: 0 0 0 0.2rem #a6d5fa;
    box-shadow: 0 0 0 0.2rem var(--fuse-primary-100);
  }
  .p-button .p-badge {
    color: var(--fuse-primary);
  }

  .p-inputgroup-addon {
    background: transparent;
    border-top: 0;
    border-left: 0;
    border-bottom: 0;
  }

  .p-inputgroup, .p-inputgroup-addon {
    padding: 0.2rem;
  }

  .p-inputtext {
    font-family: unset;
    font-size: unset;
    //padding: 0.6125rem;
    padding: 0.4rem;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: var(--fuse-primary);
    background-color: var(--fuse-primary-50);
  }

  // .p-inputgroup .kd-field-changed {
  //   background-color: var(--fuse-primary-50);
  // }

  .p-inputgroup .kd-field-left-primary {
    border-left-width: 5px !important;
    border-left-color: var(--fuse-primary) !important;
  }

  .p-inputgroup .kd-field-left-orange {
    border-left-width: 5px !important;
    border-left-color: orange !important;
  }

  .p-inputgroup .kd-field-left-red {
    border-left-width: 5px !important;
    border-left-color: red !important;
  }

  .p-inputgroup .kd-field-left-green {
    border-left-width: 5px !important;
    border-left-color: green !important;
  }

  .p-inputgroup .kd-field-mandatory {
    border-color: red;
  }

  .p-inputgroup .kd-field-lang {
    width: 2rem;
    aspect-ratio: 1 / 1;
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
    object-fit: cover;
    cursor: pointer !important;
  }

  .p-inputgroup .p-button {
    padding: 0px !important;
    border-radius: 0rem;
  }

  .p-inputtext:enabled:hover, .p-dropdown:not(.p-disabled):hover {
    border-color: var(--fuse-primary);
  }

  .p-inputtext:enabled:focus, .p-dropdown:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem var(--fuse-primary-200);
    border-color: var(--fuse-primary);
  }


  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem var(--fuse-primary-200);
    border-color: var(--fuse-primary);
  }

  .p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem var(--fuse-primary-200);
    border-color: var(--fuse-primary);
  }

  .p-autocomplete, .p-dropdown {
    width: 100% !important;
  }
  .p-dropdown {
    border-radius: 0.2rem;
  }

  .p-inputgroup-addon {
    justify-content: end;
  }

  .p-inputgroup-element {
    border-top-left-radius: 3px !important;
    border-bottom-left-radius: 3px !important;
  }

  .p-inputgroup > span {
    white-space: pre;
    font-size: 0.875rem;
  }

  .p-overlaypanel .p-overlaypanel-close {
    background: var(--fuse-primary);
    &:enabled:hover {
      background: var(--fuse-primary-400);
    }
  }

  // .ql-toolbar {
  //     border-radius: 6px 6px 0 0;
  //     padding: 0 !important;
  //     @apply bg-gray-100;
  //     @apply border-gray-300 border-opacity-100 #{'!important'};

  //     .dark & {
  //         background-color: rgba(0, 0, 0, 0.05);
  //         @apply border-gray-500 #{'!important'};
  //     }

  //     .ql-formats {
  //         margin: 11px 8px !important;
  //     }

  //     .ql-picker {

  //         &.ql-expanded {

  //             .ql-picker-label {
  //                 @apply border-gray-300;

  //                 .dark & {
  //                     @apply border-gray-500;
  //                 }
  //             }

  //             .ql-picker-options {
  //                 z-index: 10 !important;
  //                 @apply border-gray-300 bg-card;

  //                 .dark & {
  //                     @apply border-gray-500;
  //                 }
  //             }
  //         }

  //         .ql-picker-label {
  //             @apply text-default;
  //         }

  //         .ql-picker-options {

  //             .ql-picker-item {
  //                 @apply text-default;
  //             }
  //         }
  //     }

  //     .ql-stroke,
  //     .ql-stroke-mitter {
  //         stroke: var(--fuse-icon);
  //     }

  //     .ql-fill {
  //         fill: var(--fuse-icon);
  //     }

  //     button:hover,
  //     button:focus,
  //     button.ql-active,
  //     .ql-picker-label:hover,
  //     .ql-picker-label.ql-active,
  //     .ql-picker-item:hover,
  //     .ql-picker-item.ql-selected {
  //         @apply text-primary #{'!important'};

  //         .ql-stroke,
  //         .ql-stroke-mitter {
  //             stroke: var(--fuse-primary) !important;
  //         }

  //         .ql-fill {
  //             fill: var(--fuse-primary) !important;
  //         }
  //     }
  // }

  // .ql-container {
  //     overflow: hidden;
  //     border-radius: 0 0 6px 6px;
  //     @apply border-gray-300 border-opacity-100 shadow-sm #{'!important'};

  //     .dark & {
  //         @apply border-gray-500 #{'!important'};
  //     }

  //     .ql-editor {
  //         min-height: 160px;
  //         max-height: 160px;
  //         height: 160px;
  //         @apply bg-card;

  //         .dark & {
  //             background-color: rgba(0, 0, 0, 0.05);
  //         }

  //         &.ql-blank::before {
  //             @apply text-hint;
  //         }
  //     }
  // }

  .kd-grid-inline-editor .p-inputgroup {
  padding: 0 !important;
  line-height: initial !important;
  margin-left: -1px !important;
  margin-top: -1px !important;
  height: 28px !important;
  }

  .kd-grid-inline-editor .p-dropdown, .p-autocomplete {
  min-height: 28px !important;
  height: auto !important;
  }


  .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
  padding: 0.25rem 0.25rem !important;
  margin: 0.12rem !important;
  background: var(--fuse-primary-50) !important;
  line-height: 1 !important;
  }

  .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
  padding: 0 0 !important;
  margin: 0.12rem !important;
  }

  .p-autocomplete .p-autocomplete-multiple-container {
    gap: 0.2rem;
    padding: 0 0.2rem 0 0.2rem !important;
  }

  .mat-accordion .mat-expansion-panel .mat-expansion-panel-body {
  line-height: 1 !important;
  }

  .p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):hover {
    box-shadow: 0 0 0 0.2rem var(--fuse-primary-200);
    border-color: var(--fuse-primary);
  }

  /* TAILWIND */
  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {;
    color: black;
    background-color: var(--fuse-primary-100);
  }

  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
    background-color: var(--fuse-primary-50);
  }

  .p-checkbox .p-checkbox-box.p-highlight {
    border-color: var(--fuse-primary);
    background: var(--fuse-primary);
  }

  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: var(--fuse-primary-300);
    background: var(--fuse-primary-300);
  }

  .p-inputgroup-addon:last-child, .p-inputgroup button:last-child, .p-inputgroup input:last-child, .p-inputgroup > .p-inputwrapper:last-child > .p-component, .p-inputgroup > .p-inputwrapper:last-child > .p-component > .p-inputtext {
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
  }

  .p-inputgroup > .p-inputwrapper:first-child > .p-component, .p-inputgroup > .p-inputwrapper:last-child > .p-component > .p-inputtext {
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
  }

  p-inputmask > .p-inputmask {
    width: 100% !important;
  }

  .p-inputgroup .p-inputwrapper>.p-component {
    width: 100%;
  }

  .p-button.p-button-icon-only {
    width: 3rem !important;
  }

  .p-button.p-button-raised {
    box-shadow: 0 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }

  .p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem var(--fuse-primary-200);
    border-color: var(--fuse-primary);
  }

  .p-chips .p-chips-multiple-container .p-chips-token {
    background: var(--fuse-primary-100);
    color: inherit;
    border-radius: 0.2rem;
  }

  .p-chips-multiple-container {
    border-top-left-radius: 3px !important;
    border-bottom-left-radius: 3px !important;
  }

  .p-chips .p-chips-multiple-container {
    padding: 0 0.2rem 0 0.2rem !important;
    gap: 0.2rem !important;
  }

  .p-chips .p-chips-multiple-container .p-chips-input-token input {
    font-family: inherit !important;
    font-size: inherit !important;
  }

  .p-chips .p-chips-multiple-container .p-chips-token {
    padding: 0.2rem 0.4rem !important;
    border-radius: 0.2rem !important;
    color: inherit !important;
    background: var(--fuse-primary-100) !important;
  }

  .kd-null-no-placeholder .p-dropdown-label {
    color: white !important;
  }

  .p-password {
    width: 100% !important;
  }
  p-password.ng-invalid.ng-dirty > .p-password > .p-inputtext {
    border-color: #e24c4c;
  }
  p-inputmask.ng-dirty.ng-invalid > .p-inputtext {
    border-color: #e24c4c;
  }

  p-tree .p-tree {
    height: 100% !important;
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
    background: var(--fuse-primary-50);
    color: var(--fuse-primary);
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
    box-shadow: inset 0 0 0 0.15rem var(--fuse-primary-200)
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-content {
    padding: 0.2rem !important;
  }

  .p-autocomplete-dd .p-autocomplete-dropdown {
    border-radius: 0;
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
  }
}