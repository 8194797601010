/* FUSE ALERT */
fuse-alert {
    display: block;

    /* Common */
    .fuse-alert-container {
        position: relative;
        display: flex;
        padding: 16px;
        font-size: 14px;
        line-height: 1;

        /* All icons */
        .mat-icon {
            color: currentColor !important;
        }

        /* Icon */
        .fuse-alert-icon {
            display: flex;
            align-items: flex-start;

            .fuse-alert-custom-icon,
            .fuse-alert-default-icon {
                display: none;
                align-items: center;
                justify-content: center;
                border-radius: 50%;

                &:not(:empty) {
                    display: flex;
                    margin-right: 12px;
                }
            }

            .fuse-alert-default-icon {
                .mat-icon {
                    @apply icon-size-5;
                }
            }

            .fuse-alert-custom-icon {
                display: none;

                &:not(:empty) {
                    display: flex;

                    + .fuse-alert-default-icon {
                        display: none;
                    }
                }
            }
        }

        /* Content */
        .fuse-alert-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            line-height: 1;

            /* Title */
            .fuse-alert-title {
                display: none;
                font-weight: 600;
                line-height: 20px;

                &:not(:empty) {
                    display: block;

                    /* Alert that comes after the title */
                    + .fuse-alert-message {
                        &:not(:empty) {
                            margin-top: 4px;
                        }
                    }
                }
            }

            /* Alert */
            .fuse-alert-message {
                display: none;
                line-height: 20px;

                &:not(:empty) {
                    display: block;
                }
            }
        }

        /* Dismiss button */
        .fuse-alert-dismiss-button {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 32px !important;
            min-width: 32px !important;
            height: 32px !important;
            min-height: 32px !important;
            line-height: 32px !important;

            .mat-icon {
                @apply icon-size-4;
            }
        }
    }

    /* Dismissible */
    &.fuse-alert-dismissible {
        .fuse-alert-container {
            .fuse-alert-content {
                margin-right: 32px;
            }
        }
    }

    &:not(.fuse-alert-dismissible) {
        .fuse-alert-container {
            .fuse-alert-dismiss-button {
                display: none !important;
            }
        }
    }

    /* Border */
    &.fuse-alert-appearance-border {
        .fuse-alert-container {
            position: relative;
            overflow: hidden;
            border-radius: 6px;
            @apply bg-card shadow-md;

            .fuse-alert-border {
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                width: 4px;
            }

            .fuse-alert-message {
                @apply text-gray-600;
            }
        }

        /* Primary */
        &.fuse-alert-type-primary {
            .fuse-alert-container {
                .fuse-alert-border {
                    @apply bg-primary;
                }

                .fuse-alert-title,
                .fuse-alert-icon {
                    @apply text-primary;
                }

                .dark & {
                    @apply bg-gray-700;

                    .fuse-alert-border {
                        @apply bg-primary-400;
                    }

                    .fuse-alert-title,
                    .fuse-alert-icon {
                        @apply text-primary-400;
                    }

                    .fuse-alert-message {
                        @apply text-gray-300;
                    }

                    code {
                        @apply bg-gray-400 text-gray-800;
                    }
                }
            }
        }

        /* Accent */
        &.fuse-alert-type-accent {
            .fuse-alert-container {
                .fuse-alert-border {
                    @apply bg-accent;
                }

                .fuse-alert-title,
                .fuse-alert-icon {
                    @apply text-accent;
                }

                .dark & {
                    @apply bg-gray-700;

                    .fuse-alert-border {
                        @apply bg-accent-400;
                    }

                    .fuse-alert-title,
                    .fuse-alert-icon {
                        @apply text-accent-400;
                    }

                    .fuse-alert-message {
                        @apply text-gray-300;
                    }

                    code {
                        @apply bg-gray-400 text-gray-800;
                    }
                }
            }
        }

        /* Warn */
        &.fuse-alert-type-warn {
            .fuse-alert-container {
                .fuse-alert-border {
                    @apply bg-warn;
                }

                .fuse-alert-title,
                .fuse-alert-icon {
                    @apply text-warn;
                }

                .dark & {
                    @apply bg-gray-700;

                    .fuse-alert-border {
                        @apply bg-warn-400;
                    }

                    .fuse-alert-title,
                    .fuse-alert-icon {
                        @apply text-warn-400;
                    }

                    .fuse-alert-message {
                        @apply text-gray-300;
                    }

                    code {
                        @apply bg-gray-400 text-gray-800;
                    }
                }
            }
        }

        /* Basic */
        &.fuse-alert-type-basic {
            .fuse-alert-container {
                .fuse-alert-border {
                    @apply bg-gray-600;
                }

                .fuse-alert-title,
                .fuse-alert-icon {
                    @apply text-gray-600;
                }

                .dark & {
                    @apply bg-gray-700;

                    .fuse-alert-border {
                        @apply bg-gray-400;
                    }

                    .fuse-alert-title,
                    .fuse-alert-icon {
                        @apply text-gray-400;
                    }

                    .fuse-alert-message {
                        @apply text-gray-300;
                    }

                    code {
                        @apply bg-gray-400 text-gray-800;
                    }
                }
            }
        }

        /* Info */
        &.fuse-alert-type-info {
            .fuse-alert-container {
                .fuse-alert-border {
                    @apply bg-blue-600;
                }

                .fuse-alert-title,
                .fuse-alert-icon {
                    @apply text-blue-700;
                }

                .dark & {
                    @apply bg-gray-700;

                    .fuse-alert-border {
                        @apply bg-blue-400;
                    }

                    .fuse-alert-title,
                    .fuse-alert-icon {
                        @apply text-blue-400;
                    }

                    .fuse-alert-message {
                        @apply text-gray-300;
                    }

                    code {
                        @apply bg-gray-400 text-gray-800;
                    }
                }
            }
        }

        /* Success */
        &.fuse-alert-type-success {
            .fuse-alert-container {
                .fuse-alert-border {
                    @apply bg-green-500;
                }

                .fuse-alert-title,
                .fuse-alert-icon {
                    @apply text-green-500;
                }

                .dark & {
                    @apply bg-gray-700;

                    .fuse-alert-border {
                        @apply bg-green-400;
                    }

                    .fuse-alert-title,
                    .fuse-alert-icon {
                        @apply text-green-400;
                    }

                    .fuse-alert-message {
                        @apply text-gray-300;
                    }

                    code {
                        @apply bg-gray-400 text-gray-800;
                    }
                }
            }
        }

        /* Warning */
        &.fuse-alert-type-warning {
            .fuse-alert-container {
                .fuse-alert-border {
                    @apply bg-amber-500;
                }

                .fuse-alert-title,
                .fuse-alert-icon {
                    @apply text-amber-500;
                }

                .dark & {
                    @apply bg-gray-700;

                    .fuse-alert-border {
                        @apply bg-amber-400;
                    }

                    .fuse-alert-title,
                    .fuse-alert-icon {
                        @apply text-amber-400;
                    }

                    .fuse-alert-message {
                        @apply text-gray-300;
                    }

                    code {
                        @apply bg-gray-400 text-gray-800;
                    }
                }
            }
        }

        /* Error */
        &.fuse-alert-type-error {
            .fuse-alert-container {
                .fuse-alert-border {
                    @apply bg-red-600;
                }

                .fuse-alert-title,
                .fuse-alert-icon {
                    @apply text-red-700;
                }

                .dark & {
                    @apply bg-gray-700;

                    .fuse-alert-border {
                        @apply bg-red-400;
                    }

                    .fuse-alert-title,
                    .fuse-alert-icon {
                        @apply text-red-400;
                    }

                    .fuse-alert-message {
                        @apply text-gray-300;
                    }

                    code {
                        @apply bg-gray-400 text-gray-800;
                    }
                }
            }
        }
    }

    /* Fill */
    &.fuse-alert-appearance-fill {
        .fuse-alert-container {
            border-radius: 6px;

            .fuse-alert-dismiss-button {
                @apply text-white;
            }
        }

        /* Primary */
        &.fuse-alert-type-primary {
            .fuse-alert-container {
                @apply bg-primary-600;

                .fuse-alert-icon {
                    @apply text-white;
                }

                .fuse-alert-title {
                    @apply text-white;
                }

                .fuse-alert-message {
                    @apply text-primary-100;
                }

                code {
                    @apply bg-primary-200 text-primary-800;
                }
            }
        }

        /* Accent */
        &.fuse-alert-type-accent {
            .fuse-alert-container {
                @apply bg-accent-600;

                .fuse-alert-icon {
                    @apply text-white;
                }

                .fuse-alert-title {
                    @apply text-white;
                }

                .fuse-alert-message {
                    @apply text-accent-100;
                }

                code {
                    @apply bg-accent-200 text-accent-800;
                }
            }
        }

        /* Warn */
        &.fuse-alert-type-warn {
            .fuse-alert-container {
                @apply bg-warn-600;

                .fuse-alert-icon {
                    @apply text-white;
                }

                .fuse-alert-title {
                    @apply text-white;
                }

                .fuse-alert-message {
                    @apply text-warn-100;
                }

                code {
                    @apply bg-warn-200 text-warn-800;
                }
            }
        }

        /* Basic */
        &.fuse-alert-type-basic {
            .fuse-alert-container {
                @apply bg-gray-600;

                .fuse-alert-icon {
                    @apply text-white;
                }

                .fuse-alert-title {
                    @apply text-white;
                }

                .fuse-alert-message {
                    @apply text-gray-100;
                }

                code {
                    @apply bg-gray-200 text-gray-800;
                }
            }
        }

        /* Info */
        &.fuse-alert-type-info {
            .fuse-alert-container {
                @apply bg-blue-600;

                .fuse-alert-icon {
                    @apply text-white;
                }

                .fuse-alert-title {
                    @apply text-white;
                }

                .fuse-alert-message {
                    @apply text-blue-100;
                }

                code {
                    @apply bg-blue-200 text-blue-800;
                }
            }
        }

        /* Success */
        &.fuse-alert-type-success {
            .fuse-alert-container {
                @apply bg-green-600;

                .fuse-alert-icon {
                    @apply text-white;
                }

                .fuse-alert-title {
                    @apply text-white;
                }

                .fuse-alert-message {
                    @apply text-green-100;
                }

                code {
                    @apply bg-green-200 text-gray-800;
                }
            }
        }

        /* Warning */
        &.fuse-alert-type-warning {
            .fuse-alert-container {
                @apply bg-amber-500;

                .fuse-alert-icon {
                    @apply text-white;
                }

                .fuse-alert-title {
                    @apply text-white;
                }

                .fuse-alert-message {
                    @apply text-amber-100;
                }

                code {
                    @apply bg-amber-200 text-amber-800;
                }
            }
        }

        /* Error */
        &.fuse-alert-type-error {
            .fuse-alert-container {
                @apply bg-red-600;

                .fuse-alert-icon {
                    @apply text-white;
                }

                .fuse-alert-title {
                    @apply text-white;
                }

                .fuse-alert-message {
                    @apply text-red-100;
                }

                code {
                    @apply bg-red-200 text-red-800;
                }
            }
        }
    }

    /* Outline */
    &.fuse-alert-appearance-outline {
        .fuse-alert-container {
            border-radius: 6px;
        }

        /* Primary */
        &.fuse-alert-type-primary {
            .fuse-alert-container {
                @apply bg-primary-50 ring-1 ring-inset ring-primary-400;

                .fuse-alert-icon {
                    @apply text-primary-600;
                }

                .fuse-alert-title,
                .fuse-alert-dismiss-button {
                    @apply text-primary-900;
                }

                .fuse-alert-message {
                    @apply text-primary-700;
                }

                code {
                    @apply bg-primary-200 text-primary-800;
                }

                .dark & {
                    @apply bg-primary-600;

                    .fuse-alert-icon {
                        @apply text-white;
                    }

                    .fuse-alert-title,
                    .fuse-alert-dismiss-button {
                        @apply text-white;
                    }

                    .fuse-alert-message {
                        @apply text-primary-200;
                    }
                }
            }
        }

        /* Accent */
        &.fuse-alert-type-accent {
            .fuse-alert-container {
                @apply bg-accent-100 ring-1 ring-inset ring-accent-400;

                .fuse-alert-icon {
                    @apply text-accent-600;
                }

                .fuse-alert-title,
                .fuse-alert-dismiss-button {
                    @apply text-accent-900;
                }

                .fuse-alert-message {
                    @apply text-accent-700;
                }

                code {
                    @apply bg-accent-200 text-accent-800;
                }

                .dark & {
                    @apply bg-accent-600;

                    .fuse-alert-icon {
                        @apply text-white;
                    }

                    .fuse-alert-title,
                    .fuse-alert-dismiss-button {
                        @apply text-white;
                    }

                    .fuse-alert-message {
                        @apply text-accent-200;
                    }
                }
            }
        }

        /* Warn */
        &.fuse-alert-type-warn {
            .fuse-alert-container {
                @apply bg-warn-50 ring-1 ring-inset ring-warn-400;

                .fuse-alert-icon {
                    @apply text-warn-600;
                }

                .fuse-alert-title,
                .fuse-alert-dismiss-button {
                    @apply text-warn-900;
                }

                .fuse-alert-message {
                    @apply text-warn-700;
                }

                code {
                    @apply bg-warn-200 text-warn-800;
                }

                .dark & {
                    @apply bg-warn-600;

                    .fuse-alert-icon {
                        @apply text-white;
                    }

                    .fuse-alert-title,
                    .fuse-alert-dismiss-button {
                        @apply text-white;
                    }

                    .fuse-alert-message {
                        @apply text-warn-200;
                    }
                }
            }
        }

        /* Basic */
        &.fuse-alert-type-basic {
            .fuse-alert-container {
                @apply bg-gray-100 ring-1 ring-inset ring-gray-400;

                .fuse-alert-icon {
                    @apply text-gray-600;
                }

                .fuse-alert-title,
                .fuse-alert-dismiss-button {
                    @apply text-gray-900;
                }

                .fuse-alert-message {
                    @apply text-gray-700;
                }

                code {
                    @apply bg-gray-200 text-gray-800;
                }

                .dark & {
                    @apply bg-gray-600;

                    .fuse-alert-icon {
                        @apply text-white;
                    }

                    .fuse-alert-title,
                    .fuse-alert-dismiss-button {
                        @apply text-white;
                    }

                    .fuse-alert-message {
                        @apply text-gray-200;
                    }
                }
            }
        }

        /* Info */
        &.fuse-alert-type-info {
            .fuse-alert-container {
                @apply bg-blue-50 ring-1 ring-inset ring-blue-400;

                .fuse-alert-icon {
                    @apply text-blue-600;
                }

                .fuse-alert-title,
                .fuse-alert-dismiss-button {
                    @apply text-blue-900;
                }

                .fuse-alert-message {
                    @apply text-blue-700;
                }

                code {
                    @apply bg-blue-200 text-blue-800;
                }

                .dark & {
                    @apply bg-blue-600;

                    .fuse-alert-icon {
                        @apply text-white;
                    }

                    .fuse-alert-title,
                    .fuse-alert-dismiss-button {
                        @apply text-white;
                    }

                    .fuse-alert-message {
                        @apply text-blue-200;
                    }
                }
            }
        }

        /* Success */
        &.fuse-alert-type-success {
            .fuse-alert-container {
                @apply bg-green-50 ring-1 ring-inset ring-green-400;

                .fuse-alert-icon {
                    @apply text-green-600;
                }

                .fuse-alert-title,
                .fuse-alert-dismiss-button {
                    @apply text-green-900;
                }

                .fuse-alert-message {
                    @apply text-green-700;
                }

                code {
                    @apply bg-green-200 text-green-800;
                }

                .dark & {
                    @apply bg-green-600;

                    .fuse-alert-icon {
                        @apply text-white;
                    }

                    .fuse-alert-title,
                    .fuse-alert-dismiss-button {
                        @apply text-white;
                    }

                    .fuse-alert-message {
                        @apply text-green-200;
                    }
                }
            }
        }

        /* Warning */
        &.fuse-alert-type-warning {
            .fuse-alert-container {
                @apply bg-amber-50 ring-1 ring-inset ring-amber-400;

                .fuse-alert-icon {
                    @apply text-amber-600;
                }

                .fuse-alert-title,
                .fuse-alert-dismiss-button {
                    @apply text-amber-900;
                }

                .fuse-alert-message {
                    @apply text-amber-700;
                }

                code {
                    @apply bg-amber-200 text-amber-800;
                }

                .dark & {
                    @apply bg-amber-600;

                    .fuse-alert-icon {
                        @apply text-white;
                    }

                    .fuse-alert-title,
                    .fuse-alert-dismiss-button {
                        @apply text-white;
                    }

                    .fuse-alert-message {
                        @apply text-amber-200;
                    }
                }
            }
        }

        /* Error */
        &.fuse-alert-type-error {
            .fuse-alert-container {
                @apply bg-red-50 ring-1 ring-inset ring-red-400;

                .fuse-alert-icon {
                    @apply text-red-600;
                }

                .fuse-alert-title,
                .fuse-alert-dismiss-button {
                    @apply text-red-900;
                }

                .fuse-alert-message {
                    @apply text-red-700;
                }

                code {
                    @apply bg-red-200 text-red-800;
                }

                .dark & {
                    @apply bg-red-600;

                    .fuse-alert-icon {
                        @apply text-white;
                    }

                    .fuse-alert-title,
                    .fuse-alert-dismiss-button {
                        @apply text-white;
                    }

                    .fuse-alert-message {
                        @apply text-red-200;
                    }
                }
            }
        }
    }

    /* Soft */
    &.fuse-alert-appearance-soft {
        .fuse-alert-container {
            border-radius: 6px;
        }

        /* Primary */
        &.fuse-alert-type-primary {
            .fuse-alert-container {
                @apply bg-primary-50;

                .fuse-alert-icon {
                    @apply text-primary-600;
                }

                .fuse-alert-title,
                .fuse-alert-dismiss-button {
                    @apply text-primary-900;
                }

                .fuse-alert-message {
                    @apply text-primary-700;
                }

                code {
                    @apply bg-primary-200 text-primary-800;
                }

                .dark & {
                    @apply bg-primary-600;

                    .fuse-alert-icon {
                        @apply text-white;
                    }

                    .fuse-alert-title,
                    .fuse-alert-dismiss-button {
                        @apply text-white;
                    }

                    .fuse-alert-message {
                        @apply text-primary-200;
                    }
                }
            }
        }

        /* Accent */
        &.fuse-alert-type-accent {
            .fuse-alert-container {
                @apply bg-accent-100;

                .fuse-alert-icon {
                    @apply text-accent-600;
                }

                .fuse-alert-title,
                .fuse-alert-dismiss-button {
                    @apply text-accent-900;
                }

                .fuse-alert-message {
                    @apply text-accent-700;
                }

                code {
                    @apply bg-accent-200 text-accent-800;
                }

                .dark & {
                    @apply bg-accent-600;

                    .fuse-alert-icon {
                        @apply text-white;
                    }

                    .fuse-alert-title,
                    .fuse-alert-dismiss-button {
                        @apply text-white;
                    }

                    .fuse-alert-message {
                        @apply text-accent-200;
                    }
                }
            }
        }

        /* Warn */
        &.fuse-alert-type-warn {
            .fuse-alert-container {
                @apply bg-warn-50;

                .fuse-alert-icon {
                    @apply text-warn-600;
                }

                .fuse-alert-title,
                .fuse-alert-dismiss-button {
                    @apply text-warn-900;
                }

                .fuse-alert-message {
                    @apply text-warn-700;
                }

                code {
                    @apply bg-warn-200 text-warn-800;
                }

                .dark & {
                    @apply bg-warn-600;

                    .fuse-alert-icon {
                        @apply text-white;
                    }

                    .fuse-alert-title,
                    .fuse-alert-dismiss-button {
                        @apply text-white;
                    }

                    .fuse-alert-message {
                        @apply text-warn-200;
                    }
                }
            }
        }

        /* Basic */
        &.fuse-alert-type-basic {
            .fuse-alert-container {
                @apply bg-gray-100;

                .fuse-alert-icon {
                    @apply text-gray-600;
                }

                .fuse-alert-title,
                .fuse-alert-dismiss-button {
                    @apply text-gray-900;
                }

                .fuse-alert-message {
                    @apply text-gray-700;
                }

                code {
                    @apply bg-gray-200 text-gray-800;
                }

                .dark & {
                    @apply bg-gray-600;

                    .fuse-alert-icon {
                        @apply text-white;
                    }

                    .fuse-alert-title,
                    .fuse-alert-dismiss-button {
                        @apply text-white;
                    }

                    .fuse-alert-message {
                        @apply text-gray-200;
                    }
                }
            }
        }

        /* Info */
        &.fuse-alert-type-info {
            .fuse-alert-container {
                @apply bg-blue-50;

                .fuse-alert-icon {
                    @apply text-blue-600;
                }

                .fuse-alert-title,
                .fuse-alert-dismiss-button {
                    @apply text-blue-900;
                }

                .fuse-alert-message {
                    @apply text-blue-700;
                }

                code {
                    @apply bg-blue-200 text-blue-800;
                }

                .dark & {
                    @apply bg-blue-600;

                    .fuse-alert-icon {
                        @apply text-white;
                    }

                    .fuse-alert-title,
                    .fuse-alert-dismiss-button {
                        @apply text-white;
                    }

                    .fuse-alert-message {
                        @apply text-blue-200;
                    }
                }
            }
        }

        /* Success */
        &.fuse-alert-type-success {
            .fuse-alert-container {
                @apply bg-green-50;

                .fuse-alert-icon {
                    @apply text-green-600;
                }

                .fuse-alert-title,
                .fuse-alert-dismiss-button {
                    @apply text-green-900;
                }

                .fuse-alert-message {
                    @apply text-green-700;
                }

                code {
                    @apply bg-green-200 text-green-800;
                }

                .dark & {
                    @apply bg-green-600;

                    .fuse-alert-icon {
                        @apply text-white;
                    }

                    .fuse-alert-title,
                    .fuse-alert-dismiss-button {
                        @apply text-white;
                    }

                    .fuse-alert-message {
                        @apply text-green-200;
                    }
                }
            }
        }

        /* Warning */
        &.fuse-alert-type-warning {
            .fuse-alert-container {
                @apply bg-amber-50;

                .fuse-alert-icon {
                    @apply text-amber-600;
                }

                .fuse-alert-title,
                .fuse-alert-dismiss-button {
                    @apply text-amber-900;
                }

                .fuse-alert-message {
                    @apply text-amber-700;
                }

                code {
                    @apply bg-amber-200 text-amber-800;
                }

                .dark & {
                    @apply bg-amber-600;

                    .fuse-alert-icon {
                        @apply text-white;
                    }

                    .fuse-alert-title,
                    .fuse-alert-dismiss-button {
                        @apply text-white;
                    }

                    .fuse-alert-message {
                        @apply text-amber-200;
                    }
                }
            }
        }

        /* Error */
        &.fuse-alert-type-error {
            .fuse-alert-container {
                @apply bg-red-50;

                .fuse-alert-icon {
                    @apply text-red-600;
                }

                .fuse-alert-title,
                .fuse-alert-dismiss-button {
                    @apply text-red-900;
                }

                .fuse-alert-message {
                    @apply text-red-700;
                }

                code {
                    @apply bg-red-200 text-red-800;
                }

                .dark & {
                    @apply bg-red-600;

                    .fuse-alert-icon {
                        @apply text-white;
                    }

                    .fuse-alert-title,
                    .fuse-alert-dismiss-button {
                        @apply text-white;
                    }

                    .fuse-alert-message {
                        @apply text-red-200;
                    }
                }
            }
        }
    }
}


/* FUSE CARD */
fuse-card {
    position: relative;
    display: flex;
    overflow: hidden;
    @apply bg-card rounded-2xl shadow;

    /* Flippable */
    &.fuse-card-flippable {
        border-radius: 0;
        overflow: visible;
        transform-style: preserve-3d;
        transition: transform 1s;
        perspective: 600px;
        background: transparent;
        @apply shadow-none;

        &.fuse-card-face-back {
            .fuse-card-front {
                visibility: hidden;
                opacity: 0;
                transform: rotateY(180deg);
            }

            .fuse-card-back {
                visibility: visible;
                opacity: 1;
                transform: rotateY(360deg);
            }
        }

        .fuse-card-front,
        .fuse-card-back {
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;
            z-index: 10;
            transition:
                transform 0.5s ease-out 0s,
                visibility 0s ease-in 0.2s,
                opacity 0s ease-in 0.2s;
            backface-visibility: hidden;
            @apply bg-card rounded-2xl shadow;
        }

        .fuse-card-front {
            position: relative;
            opacity: 1;
            visibility: visible;
            transform: rotateY(0deg);
            overflow: hidden;
        }

        .fuse-card-back {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: 0;
            visibility: hidden;
            transform: rotateY(180deg);
            overflow: hidden auto;
        }
    }
}


/* FUSE DRAWER */
/* Variables */
:root {
  --fuse-drawer-width: 320px;
}

fuse-drawer {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: var(--fuse-drawer-width);
    min-width: var(--fuse-drawer-width);
    max-width: var(--fuse-drawer-width);
    z-index: 300;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.35);
    @apply bg-card;

    /* Animations */
    &.fuse-drawer-animations-enabled {
        transition-duration: 400ms;
        transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
        transition-property: visibility, margin-left, margin-right, transform,
            width, max-width, min-width;

        .fuse-drawer-content {
            transition-duration: 400ms;
            transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
            transition-property: width, max-width, min-width;
        }
    }

    /* Over mode */
    &.fuse-drawer-mode-over {
        position: absolute;
        top: 0;
        bottom: 0;

        /* Fixed mode */
        &.fuse-drawer-fixed {
            position: fixed;
        }
    }

    /* Left position */
    &.fuse-drawer-position-left {
        /* Side mode */
        &.fuse-drawer-mode-side {
            margin-left: calc(var(--fuse-drawer-width) * -1);

            &.fuse-drawer-opened {
                margin-left: 0;
            }
        }

        /* Over mode */
        &.fuse-drawer-mode-over {
            left: 0;
            transform: translate3d(-100%, 0, 0);

            &.fuse-drawer-opened {
                transform: translate3d(0, 0, 0);
            }
        }

        /* Content */
        .fuse-drawer-content {
            left: 0;
        }
    }

    /* Right position */
    &.fuse-drawer-position-right {
        /* Side mode */
        &.fuse-drawer-mode-side {
            margin-right: calc(var(--fuse-drawer-width) * -1);

            &.fuse-drawer-opened {
                margin-right: 0;
            }
        }

        /* Over mode */
        &.fuse-drawer-mode-over {
            right: 0;
            transform: translate3d(100%, 0, 0);

            &.fuse-drawer-opened {
                transform: translate3d(0, 0, 0);
            }
        }

        /* Content */
        .fuse-drawer-content {
            right: 0;
        }
    }

    /* Content */
    .fuse-drawer-content {
        position: absolute;
        display: flex;
        flex: 1 1 auto;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        @apply bg-card;
    }
}

/* Overlay */
.fuse-drawer-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 299;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.6);

    /* Fixed mode */
    &.fuse-drawer-overlay-fixed {
        position: fixed;
    }

    /* Transparent overlay */
    &.fuse-drawer-overlay-transparent {
        background-color: transparent;
    }
}


/* FUSE HIGHLIGHT */
textarea[fuse-highlight] {
  display: none;
}

/* FUSE LOADING-BAR */
fuse-loading-bar {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 3px;
  margin-top: -2px;
}

/* FUSE NAVIGATION HORIZONTAL */
/* Root navigation specific */
fuse-horizontal-navigation {

  .fuse-horizontal-navigation-wrapper {
      display: flex;
      align-items: center;

      /* Basic, Branch */
      fuse-horizontal-navigation-basic-item,
      fuse-horizontal-navigation-branch-item {

          @screen sm {

              &:hover {

                  .fuse-horizontal-navigation-item-wrapper {
                      @apply bg-hover;
                  }
              }
          }

          .fuse-horizontal-navigation-item-wrapper {
              border-radius: 4px;
              overflow: hidden;

              .fuse-horizontal-navigation-item {
                  padding: 0 16px;
                  cursor: pointer;
                  user-select: none;

                  .fuse-horizontal-navigation-item-icon {
                      margin-right: 12px;
                  }
              }
          }
      }

      /* Basic - When item active (current link) */
      fuse-horizontal-navigation-basic-item {

          .fuse-horizontal-navigation-item-active,
          .fuse-horizontal-navigation-item-active-forced {

              .fuse-horizontal-navigation-item-title {
                  @apply text-primary #{'!important'};
              }

              .fuse-horizontal-navigation-item-subtitle {
                  @apply text-primary-400 #{'!important'};

                  .dark & {
                      @apply text-primary-600 #{'!important'};
                  }
              }

              .fuse-horizontal-navigation-item-icon {
                  @apply text-primary #{'!important'};
              }
          }
      }

      /* Branch - When menu open */
      fuse-horizontal-navigation-branch-item {

          .fuse-horizontal-navigation-menu-active,
          .fuse-horizontal-navigation-menu-active-forced {

              .fuse-horizontal-navigation-item-wrapper {
                  @apply bg-hover;
              }
          }
      }

      /* Spacer */
      fuse-horizontal-navigation-spacer-item {
          margin: 12px 0;
      }
  }
}

/* Menu panel specific */
.fuse-horizontal-navigation-menu-panel {

  .fuse-horizontal-navigation-menu-item {
      height: auto;
      min-height: 0;
      line-height: normal;
      white-space: normal;

      /* Basic, Branch */
      fuse-horizontal-navigation-basic-item,
      fuse-horizontal-navigation-branch-item,
      fuse-horizontal-navigation-divider-item {
          display: flex;
          flex: 1 1 auto;
      }

      /* Divider */
      fuse-horizontal-navigation-divider-item {
          margin: 8px -16px;

          .fuse-horizontal-navigation-item-wrapper {
              height: 1px;
              box-shadow: 0 1px 0 0;
          }
      }
  }
}

/* Navigation menu item common */
.fuse-horizontal-navigation-menu-item {

  /* Basic - When item active (current link) */
  fuse-horizontal-navigation-basic-item {

      .fuse-horizontal-navigation-item-active,
      .fuse-horizontal-navigation-item-active-forced {

          .fuse-horizontal-navigation-item-title {
              @apply text-primary #{'!important'};
          }

          .fuse-horizontal-navigation-item-subtitle {
              @apply text-primary-400 #{'!important'};

              .dark & {
                  @apply text-primary-600 #{'!important'};
              }
          }

          .fuse-horizontal-navigation-item-icon {
              @apply text-primary #{'!important'};
          }
      }
  }

  .fuse-horizontal-navigation-item-wrapper {
      width: 100%;

      &.fuse-horizontal-navigation-item-has-subtitle {

          .fuse-horizontal-navigation-item {
              min-height: 56px;
          }
      }

      .fuse-horizontal-navigation-item {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          min-height: 48px;
          width: 100%;
          font-size: 13px;
          font-weight: 500;
          text-decoration: none;

          .fuse-horizontal-navigation-item-title-wrapper {

              .fuse-horizontal-navigation-item-subtitle {
                  font-size: 12px;
              }
          }

          .fuse-horizontal-navigation-item-badge {
              margin-left: auto;

              .fuse-horizontal-navigation-item-badge-content {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 10px;
                  font-weight: 600;
                  white-space: nowrap;
                  height: 20px;
              }
          }
      }
  }
}

/* FUSE NAVIGATION VERTICAL DEFAULT */
/* Variables */
:root {
  --fuse-vertical-navigation-width: 280px;
}

fuse-vertical-navigation {
  position: sticky;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  top: 0;
  width: var(--fuse-vertical-navigation-width);
  min-width: var(--fuse-vertical-navigation-width);
  max-width: var(--fuse-vertical-navigation-width);
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  z-index: 200;

  /* ----------------------------------------------------------------------------------------------------- */
  /* @ Navigation Drawer
  /* ----------------------------------------------------------------------------------------------------- */

  /* Animations */
  &.fuse-vertical-navigation-animations-enabled {
      transition-duration: 400ms;
      transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
      transition-property: visibility, margin-left, margin-right, transform, width, max-width, min-width;

      /* Wrapper */
      .fuse-vertical-navigation-wrapper {
          transition-duration: 400ms;
          transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
          transition-property: width, max-width, min-width;
      }
  }

  /* Over mode */
  &.fuse-vertical-navigation-mode-over {
      position: fixed;
      top: 0;
      bottom: 0;
  }

  /* Left position */
  &.fuse-vertical-navigation-position-left {

      /* Side mode */
      &.fuse-vertical-navigation-mode-side {
          margin-left: calc(#{var(--fuse-vertical-navigation-width)} * -1);

          &.fuse-vertical-navigation-opened {
              margin-left: 0;
          }
      }

      /* Over mode */
      &.fuse-vertical-navigation-mode-over {
          left: 0;
          transform: translate3d(-100%, 0, 0);

          &.fuse-vertical-navigation-opened {
              transform: translate3d(0, 0, 0);
          }
      }

      /* Wrapper */
      .fuse-vertical-navigation-wrapper {
          left: 0;
      }
  }

  /* Right position */
  &.fuse-vertical-navigation-position-right {

      /* Side mode */
      &.fuse-vertical-navigation-mode-side {
          margin-right: calc(var(--fuse-vertical-navigation-width) * -1);

          &.fuse-vertical-navigation-opened {
              margin-right: 0;
          }
      }

      /* Over mode */
      &.fuse-vertical-navigation-mode-over {
          right: 0;
          transform: translate3d(100%, 0, 0);

          &.fuse-vertical-navigation-opened {
              transform: translate3d(0, 0, 0);
          }
      }

      /* Wrapper */
      .fuse-vertical-navigation-wrapper {
          right: 0;
      }
  }

  /* Inner mode */
  &.fuse-vertical-navigation-inner {
      position: relative;
      width: auto;
      min-width: 0;
      max-width: none;
      height: auto;
      min-height: 0;
      max-height: none;
      box-shadow: none;

      .fuse-vertical-navigation-wrapper {
          position: relative;
          overflow: visible;
          height: auto;

          .fuse-vertical-navigation-content {
              overflow: visible !important;
          }
      }
  }

  /* Wrapper */
  .fuse-vertical-navigation-wrapper {
      position: absolute;
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      z-index: 10;
      background: inherit;
      box-shadow: inset -1px 0 0 var(--fuse-border);

      /* Header */
      .fuse-vertical-navigation-header {

      }

      /* Content */
      .fuse-vertical-navigation-content {
          flex: 1 1 auto;
          overflow-x: hidden;
          overflow-y: auto;
          overscroll-behavior: contain;

          /* Divider */
          > fuse-vertical-navigation-divider-item {
              margin: 24px 0;
          }

          /* Group */
          > fuse-vertical-navigation-group-item {
              margin-top: 24px;
          }
      }

      /* Footer */
      .fuse-vertical-navigation-footer {

      }
  }

  /* Aside wrapper */
  .fuse-vertical-navigation-aside-wrapper {
      position: absolute;
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
      top: 0;
      bottom: 0;
      left: var(--fuse-vertical-navigation-width);
      width: var(--fuse-vertical-navigation-width);
      height: 100%;
      z-index: 5;
      overflow-x: hidden;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      transition-duration: 400ms;
      transition-property: left, right;
      transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
      background: inherit;

      > fuse-vertical-navigation-aside-item {
          padding: 24px 0;

          /* First item of the aside */
          > .fuse-vertical-navigation-item-wrapper {
              display: none !important;
          }
      }
  }

  &.fuse-vertical-navigation-position-right {

      .fuse-vertical-navigation-aside-wrapper {
          left: auto;
          right: var(--fuse-vertical-navigation-width);
      }
  }

  /* ----------------------------------------------------------------------------------------------------- */
  /* @ Navigation Items
  /* ----------------------------------------------------------------------------------------------------- */

  /* Navigation items common */
  fuse-vertical-navigation-aside-item,
  fuse-vertical-navigation-basic-item,
  fuse-vertical-navigation-collapsable-item,
  fuse-vertical-navigation-divider-item,
  fuse-vertical-navigation-group-item,
  fuse-vertical-navigation-spacer-item {
      display: flex;
      flex-direction: column;
      flex: 1 0 auto;
      user-select: none;

      .fuse-vertical-navigation-item-wrapper {

          .fuse-vertical-navigation-item {
              position: relative;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              padding: 10px 16px;
              font-size: 13px;
              font-weight: 500;
              line-height: 20px;
              text-decoration: none;
              border-radius: 6px;

              /* Disabled state */
              &.fuse-vertical-navigation-item-disabled {
                  cursor: default;
                  opacity: 0.4;
              }

              .fuse-vertical-navigation-item-icon {
                  margin-right: 16px;
              }

              .fuse-vertical-navigation-item-title-wrapper {

                  .fuse-vertical-navigation-item-subtitle {
                      font-size: 11px;
                      line-height: 1.5;
                  }
              }

              .fuse-vertical-navigation-item-badge {
                  margin-left: auto;

                  .fuse-vertical-navigation-item-badge-content {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      font-size: 10px;
                      font-weight: 600;
                      white-space: nowrap;
                      height: 20px;
                  }
              }
          }
      }
  }

  /* Aside, Basic, Collapsable, Group */
  fuse-vertical-navigation-aside-item,
  fuse-vertical-navigation-basic-item,
  fuse-vertical-navigation-collapsable-item,
  fuse-vertical-navigation-group-item {

      > .fuse-vertical-navigation-item-wrapper {
          margin: 0 12px;
      }
  }

  /* Aside, Basic, Collapsable */
  fuse-vertical-navigation-aside-item,
  fuse-vertical-navigation-basic-item,
  fuse-vertical-navigation-collapsable-item {
      margin-bottom: 4px;

      .fuse-vertical-navigation-item {
          cursor: pointer;
      }
  }

  /* Aside */
  fuse-vertical-navigation-aside-item {

  }

  /* Basic */
  fuse-vertical-navigation-basic-item {

  }

  /* Collapsable */
  fuse-vertical-navigation-collapsable-item {

      > .fuse-vertical-navigation-item-wrapper {

          .fuse-vertical-navigation-item {

              .fuse-vertical-navigation-item-badge {

                  + .fuse-vertical-navigation-item-arrow {
                      margin-left: 8px;
                  }
              }

              .fuse-vertical-navigation-item-arrow {
                  height: 20px;
                  line-height: 20px;
                  margin-left: auto;
                  transition: transform 300ms cubic-bezier(0.25, 0.8, 0.25, 1),
                  color 375ms cubic-bezier(0.25, 0.8, 0.25, 1);
              }
          }
      }

      &.fuse-vertical-navigation-item-expanded {

          > .fuse-vertical-navigation-item-wrapper {

              .fuse-vertical-navigation-item {

                  .fuse-vertical-navigation-item-arrow {
                      transform: rotate(90deg);
                  }
              }
          }
      }

      > .fuse-vertical-navigation-item-children {
          > *:first-child {
              margin-top: 6px;
          }

          > *:last-child {
              padding-bottom: 6px;

              > .fuse-vertical-navigation-item-children {

                  > *:last-child {
                      padding-bottom: 0;
                  }
              }
          }

          .fuse-vertical-navigation-item {
              padding: 10px 16px;
          }
      }

      /* 1st level */
      .fuse-vertical-navigation-item-children {
          overflow: hidden;

          .fuse-vertical-navigation-item {
              padding-left: 56px;
          }

          /* 2nd level */
          .fuse-vertical-navigation-item-children {

              .fuse-vertical-navigation-item {
                  padding-left: 72px;
              }

              /* 3rd level */
              .fuse-vertical-navigation-item-children {

                  .fuse-vertical-navigation-item {
                      padding-left: 88px;
                  }

                  /* 4th level */
                  .fuse-vertical-navigation-item-children {

                      .fuse-vertical-navigation-item {
                          padding-left: 104px;
                      }
                  }
              }
          }
      }
  }

  /* Divider */
  fuse-vertical-navigation-divider-item {
      margin: 12px 0;

      .fuse-vertical-navigation-item-wrapper {
          height: 1px;
          box-shadow: 0 1px 0 0;
      }
  }

  /* Group */
  fuse-vertical-navigation-group-item {

      > .fuse-vertical-navigation-item-wrapper {

          .fuse-vertical-navigation-item {

              .fuse-vertical-navigation-item-badge,
              .fuse-vertical-navigation-item-icon {
                  display: none !important;
              }

              .fuse-vertical-navigation-item-title-wrapper {

                  .fuse-vertical-navigation-item-title {
                      font-size: 12px;
                      font-weight: 600;
                      letter-spacing: 0.05em;
                      text-transform: uppercase;
                  }
              }
          }
      }
  }

  /* Spacer */
  fuse-vertical-navigation-spacer-item {
      margin: 6px 0;
  }
}

@media screen and (max-width: 600px) {
    fuse-vertical-navigation {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
    }
}

/* ----------------------------------------------------------------------------------------------------- */
/* @ Overlay
/* ----------------------------------------------------------------------------------------------------- */
.fuse-vertical-navigation-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 170;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);

  + .fuse-vertical-navigation-aside-overlay {
      background-color: transparent;
  }
}

/* ----------------------------------------------------------------------------------------------------- */
/* @ Aside overlay
/* ----------------------------------------------------------------------------------------------------- */
.fuse-vertical-navigation-aside-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 169;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

/* ----------------------------------------------------------------------------------------------------- */
/* @ Navigation Items Colors
/* ----------------------------------------------------------------------------------------------------- */

/* Navigation items common */
fuse-vertical-navigation-aside-item,
fuse-vertical-navigation-basic-item,
fuse-vertical-navigation-collapsable-item,
fuse-vertical-navigation-group-item {

  .fuse-vertical-navigation-item-wrapper {

      .fuse-vertical-navigation-item {
          color: currentColor;
          white-space: nowrap;
          .fuse-vertical-navigation-item-icon {
              @apply text-current opacity-60;
          }

          .fuse-vertical-navigation-item-title-wrapper {

              .fuse-vertical-navigation-item-title {
                  @apply text-current opacity-80;
              }

              .fuse-vertical-navigation-item-subtitle {
                  @apply text-current opacity-50;
              }
          }
      }
  }
}

/* Aside, Basic, Collapsable */
fuse-vertical-navigation-aside-item,
fuse-vertical-navigation-basic-item,
fuse-vertical-navigation-collapsable-item {

  > .fuse-vertical-navigation-item-wrapper {

      .fuse-vertical-navigation-item {

          /* Active state */
          &:not(.fuse-vertical-navigation-item-disabled) {

              &.fuse-vertical-navigation-item-active,
              &.fuse-vertical-navigation-item-active-forced {
                  @apply bg-gray-800 bg-opacity-5 dark:bg-white dark:bg-opacity-12;

                  .fuse-vertical-navigation-item-icon {
                      @apply opacity-100;
                  }

                  .fuse-vertical-navigation-item-title {
                      @apply opacity-100;
                  }

                  .fuse-vertical-navigation-item-subtitle {
                      @apply opacity-100;
                  }
              }
          }

          /* Hover state */
          &:not(.fuse-vertical-navigation-item-active-forced):not(.fuse-vertical-navigation-item-active):not(.fuse-vertical-navigation-item-disabled) {

              &:hover {
                  @apply bg-gray-800 bg-opacity-5 dark:bg-white dark:bg-opacity-12;

                  .fuse-vertical-navigation-item-icon {
                      @apply opacity-100;
                  }

                  .fuse-vertical-navigation-item-title,
                  .fuse-vertical-navigation-item-arrow {
                      @apply opacity-100;
                  }

                  .fuse-vertical-navigation-item-subtitle {
                      @apply opacity-100;
                  }
              }
          }
      }
  }
}

/* Collapsable */
fuse-vertical-navigation-collapsable-item {

  /* Expanded state */
  &.fuse-vertical-navigation-item-expanded {

      > .fuse-vertical-navigation-item-wrapper {

          .fuse-vertical-navigation-item {

              .fuse-vertical-navigation-item-icon {
                  @apply opacity-100;
              }

              .fuse-vertical-navigation-item-title,
              .fuse-vertical-navigation-item-arrow {
                  @apply opacity-100;
              }

              .fuse-vertical-navigation-item-subtitle {
                  @apply opacity-100;
              }
          }
      }
  }
}

/* Group */
fuse-vertical-navigation-group-item {

  > .fuse-vertical-navigation-item-wrapper {

      .fuse-vertical-navigation-item {

          .fuse-vertical-navigation-item-title-wrapper {

              .fuse-vertical-navigation-item-title {
                  @apply opacity-100 text-primary-600 dark:text-primary-400;
              }
          }
      }
  }
}

/* FUSE NAVIGATION VERTICAL COMPACT */
/* Variables */
:root {
  --fuse-vertical-navigation-compact-width: 112px;
}

fuse-vertical-navigation {

  /* Compact appearance overrides */
  &.fuse-vertical-navigation-appearance-compact {
      width: var(--fuse-vertical-navigation-compact-width);
      min-width: var(--fuse-vertical-navigation-compact-width);
      max-width: var(--fuse-vertical-navigation-compact-width);

      /* Left positioned */
      &.fuse-vertical-navigation-position-left {

          /* Side mode */
          &.fuse-vertical-navigation-mode-side {
              margin-left: calc(var(--fuse-vertical-navigation-compact-width) * -1);
          }

          /* Opened */
          &.fuse-vertical-navigation-opened {
              margin-left: 0;
          }
      }

      /* Right positioned */
      &.fuse-vertical-navigation-position-right {

          /* Side mode */
          &.fuse-vertical-navigation-mode-side {
              margin-right: calc(var(--fuse-vertical-navigation-compact-width) * -1);
          }

          /* Opened */
          &.fuse-vertical-navigation-opened {
              margin-right: 0;
          }

          /* Aside wrapper */
          .fuse-vertical-navigation-aside-wrapper {
              left: auto;
              right: var(--fuse-vertical-navigation-compact-width);
          }
      }

      /* Wrapper */
      .fuse-vertical-navigation-wrapper {

          /* Content */
          .fuse-vertical-navigation-content {

              > fuse-vertical-navigation-aside-item,
              > fuse-vertical-navigation-basic-item {

                  .fuse-vertical-navigation-item-wrapper {
                      margin: 4px 8px 0 8px;

                      .fuse-vertical-navigation-item {
                          flex-direction: column;
                          justify-content: center;
                          padding: 12px;
                          border-radius: 6px;

                          .fuse-vertical-navigation-item-icon {
                              margin-right: 0;
                          }

                          .fuse-vertical-navigation-item-title-wrapper {
                              margin-top: 8px;

                              .fuse-vertical-navigation-item-title {
                                  font-size: 12px;
                                  font-weight: 500;
                                  text-align: center;
                                  line-height: 16px;
                              }

                              .fuse-vertical-navigation-item-subtitle {
                                  display: none !important;
                              }
                          }

                          .fuse-vertical-navigation-item-badge {
                              position: absolute;
                              top: 12px;
                              left: 64px;
                          }
                      }
                  }

                  > fuse-vertical-navigation-collapsable-item {
                      display: none
                  }

                  > fuse-vertical-navigation-group-item {

                      > .fuse-vertical-navigation-item-wrapper {
                          display: none
                      }
                  }
              }
          }
      }

      /* Aside wrapper */
      .fuse-vertical-navigation-aside-wrapper {
          left: var(--fuse-vertical-navigation-compact-width);
      }
  }
}

/* FUSE NAVIGATION VERTICAL DENSE*/
/* Variables */
:root {
  --fuse-vertical-navigation-width: 280px;
  --fuse-vertical-navigation-dense-width: 80px;
}

fuse-vertical-navigation {

  /* Dense appearance overrides */
  &.fuse-vertical-navigation-appearance-dense {

      &:not(.fuse-vertical-navigation-mode-over) {
          width: var(--fuse-vertical-navigation-dense-width);
          min-width: var(--fuse-vertical-navigation-dense-width);
          max-width: var(--fuse-vertical-navigation-dense-width);

          /* Left positioned */
          &.fuse-vertical-navigation-position-left {

              /* Side mode */
              &.fuse-vertical-navigation-mode-side {
                  margin-left: calc(var(--fuse-vertical-navigation-dense-width) * -1);
              }

              /* Opened */
              &.fuse-vertical-navigation-opened {
                  margin-left: 0;
              }
          }

          /* Right positioned */
          &.fuse-vertical-navigation-position-right {

              /* Side mode */
              &.fuse-vertical-navigation-mode-side {
                  margin-right: calc(var(--fuse-vertical-navigation-dense-width) * -1);
              }

              /* Opened */
              &.fuse-vertical-navigation-opened {
                  margin-right: 0;
              }

              /* Aside wrapper */
              .fuse-vertical-navigation-aside-wrapper {
                  left: auto;
                  right: var(--fuse-vertical-navigation-dense-width);
              }

              &.fuse-vertical-navigation-hover {

                  .fuse-vertical-navigation-aside-wrapper {
                      left: auto;
                      right: var(--fuse-vertical-navigation-width);
                  }
              }
          }
      }

      /* Wrapper */
      .fuse-vertical-navigation-wrapper {

          /* Content */
          .fuse-vertical-navigation-content {

              fuse-vertical-navigation-aside-item,
              fuse-vertical-navigation-basic-item,
              fuse-vertical-navigation-collapsable-item,
              fuse-vertical-navigation-group-item {

                  .fuse-vertical-navigation-item-wrapper {

                      .fuse-vertical-navigation-item {
                        //   width: calc(var(--fuse-vertical-navigation-dense-width) - 24px);
                        //   min-width: calc(var(--fuse-vertical-navigation-dense-width) - 24px);
                        //   max-width: calc(var(--fuse-vertical-navigation-dense-width) - 24px);

                          .fuse-vertical-navigation-item-arrow,
                          .fuse-vertical-navigation-item-badge,
                          .fuse-vertical-navigation-item-title-wrapper {
                              transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
                          }
                      }
                  }
              }

              fuse-vertical-navigation-group-item {

                  &:first-of-type {
                      margin-top: 0;
                  }
              }
          }
      }

      &:not(.fuse-vertical-navigation-hover):not(.fuse-vertical-navigation-mode-over) {

          /* Wrapper */
          .fuse-vertical-navigation-wrapper {

              /* Content */
              .fuse-vertical-navigation-content {

                  .fuse-vertical-navigation-item-wrapper {

                      .fuse-vertical-navigation-item {
                          padding: 10px 16px;

                          .fuse-vertical-navigation-item-arrow,
                          .fuse-vertical-navigation-item-badge,
                          .fuse-vertical-navigation-item-title-wrapper {
                              white-space: nowrap;
                              opacity: 0;
                          }
                      }
                  }

                  fuse-vertical-navigation-collapsable-item {

                      .fuse-vertical-navigation-item-children {
                          display: none;
                      }
                  }

                  fuse-vertical-navigation-group-item {

                      > .fuse-vertical-navigation-item-wrapper {

                          .fuse-vertical-navigation-item {

                              &:before {
                                  content: "";
                                  position: absolute;
                                  top: 20px;
                                  width: 23px;
                                  border-top-width: 2px;
                              }
                          }
                      }
                  }
              }
          }
      }

      /* Aside wrapper */
      .fuse-vertical-navigation-aside-wrapper {
          left: var(--fuse-vertical-navigation-dense-width);
      }

      /* Hover */
      &.fuse-vertical-navigation-hover {

          .fuse-vertical-navigation-wrapper {
              width: var(--fuse-vertical-navigation-width);

              .fuse-vertical-navigation-content {

                  .fuse-vertical-navigation-item-wrapper {

                      .fuse-vertical-navigation-item {
                          width: calc(var(--fuse-vertical-navigation-width) - 24px);
                          min-width: calc(var(--fuse-vertical-navigation-width) - 24px);
                          max-width: calc(var(--fuse-vertical-navigation-width) - 24px);

                          .fuse-vertical-navigation-item-arrow,
                          .fuse-vertical-navigation-item-badge,
                          .fuse-vertical-navigation-item-title-wrapper {
                              white-space: nowrap;
                              animation: removeWhiteSpaceNoWrap 1ms linear 350ms;
                              animation-fill-mode: forwards;
                          }
                      }
                  }
              }
          }

          .fuse-vertical-navigation-aside-wrapper {
              left: var(--fuse-vertical-navigation-width);
          }
      }
  }
}

@keyframes removeWhiteSpaceNoWrap {
  0% {
      white-space: nowrap
  }
  99% {
      white-space: nowrap
  }
  100% {
      white-space: normal;
  }
}


/* FUSE NAVIGATION VERTICAL THIN */
/* Variables */
:root {
  --fuse-vertical-navigation-thin-width: 80px;
}

fuse-vertical-navigation {

  /* Thin appearance overrides */
  &.fuse-vertical-navigation-appearance-thin {
      width: var(--fuse-vertical-navigation-thin-width);
      min-width: var(--fuse-vertical-navigation-thin-width);
      max-width: var(--fuse-vertical-navigation-thin-width);

      /* Left positioned */
      &.fuse-vertical-navigation-position-left {

          &.fuse-vertical-navigation-mode-side {
              margin-left: calc(var(--fuse-vertical-navigation-thin-width) * -1);
          }

          &.fuse-vertical-navigation-opened {
              margin-left: 0;
          }
      }

      /* Right positioned */
      &.fuse-vertical-navigation-position-right {

          &.fuse-vertical-navigation-mode-side {
              margin-right: calc(var(--fuse-vertical-navigation-thin-width) * -1);
          }

          &.fuse-vertical-navigation-opened {
              margin-right: 0;
          }

          .fuse-vertical-navigation-aside-wrapper {
              left: auto;
              right: var(--fuse-vertical-navigation-thin-width);
          }
      }

      /* Wrapper */
      .fuse-vertical-navigation-wrapper {

          /* Content */
          .fuse-vertical-navigation-content {

              > fuse-vertical-navigation-aside-item,
              > fuse-vertical-navigation-basic-item {
                  flex-direction: column;
                  justify-content: center;
                  height: 64px;
                  min-height: 64px;
                  max-height: 64px;
                  padding: 0 16px;

                  .fuse-vertical-navigation-item-wrapper {
                      display: flex;
                      align-items: center;
                      justify-content: center;

                      .fuse-vertical-navigation-item {
                          justify-content: center;
                          padding: 12px;
                          border-radius: 4px;

                          .fuse-vertical-navigation-item-icon {
                              margin: 0;
                          }

                          .fuse-vertical-navigation-item-arrow,
                          .fuse-vertical-navigation-item-badge-content,
                          .fuse-vertical-navigation-item-title-wrapper {
                              display: none;
                          }
                      }
                  }
              }

              > fuse-vertical-navigation-collapsable-item {
                  display: none
              }

              > fuse-vertical-navigation-group-item {

                  > .fuse-vertical-navigation-item-wrapper {
                      display: none
                  }
              }
          }
      }

      /* Aside wrapper */
      .fuse-vertical-navigation-aside-wrapper {
          left: var(--fuse-vertical-navigation-thin-width);
      }
  }
}

/* FUSE STICKY-HEADER */
sticky-header {
  position: fixed;
  top: 0;
  z-index: 10000;
  width: 100%;
  height: 50px;
  background-color: black;
  color: white;
}

/* FUSE QUICK-CHAT */
quick-chat {
  z-index: 399;

  > div {
      overflow: hidden;
  }

  &.quick-chat-opened {

      > div {
          overflow: visible;
      }
  }

  &:not(.quick-chat-opened) {

      > div {
          overflow: visible;
          animation: addOverflowHidden 1ms linear 400ms;
          animation-fill-mode: forwards;
      }
  }
}

/* Adjustments depending on the selected layout */
.quick-chat-header {
  height: 64px;

  enterprise-layout &,
  modern-layout & {
      height: 80px !important;
  }
}


/* Overlay */
.quick-chat-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 299;
  opacity: 1;
  background-color: transparent;
}

@keyframes addOverflowHidden {
  0% {
      overflow: visible
  }
  99% {
      overflow: visible;
  }
  100% {
      overflow: hidden;
  }
}

/* FUSE LAYOUT */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");
layout {
    display: flex;
    flex: 1 1 auto;
    width: 100%;
    max-width: 100%;
    min-width: 0;

    /* Base styles for individual layouts */
    > * {
        position: relative;
        display: flex;
        flex: 1 1 auto;
        width: 100%;
    }

    /* Base styles for components that load as a route */
    router-outlet {

        + * {
            position: relative;
            display: flex;
            flex: 1 1 auto;
            width: 100%;
        }
    }
}

/* TINY MCE EDITOR CSS's */
editor .tox-promotion, editor .tox-statusbar__branding {
    display: none !important;
}

editor.editor-headless .tox-editor-header, editor.editor-headless .tox-statusbar {
    display: none !important;
}

// editor:not(.editor-headless) .tox.tox-tinymce {
editor .tox.tox-tinymce {
    height: 100% !important;
}